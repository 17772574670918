import { getCurrencySymbol } from '@/components/Subscription/SubscriptionManager/helpers';
import { COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { DonationMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';

const DonationCard = ({ messageWithMeta }: CardProps<DonationMessage>) => {
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p line-clamp-3">
        just made a {getCurrencySymbol(messageWithMeta.message.currency)} {(messageWithMeta.message.amount / 100).toFixed(2)}{' '}
        <strong>contribution</strong> to the{' '}
        <CardHabitatLink message={messageWithMeta.message}>
          {truncateString(messageWithMeta.message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)} at the{' '}
          {truncateString(messageWithMeta.message.zoo.name, COMMENT_TRUNCATE_LEN_SHORT)}, way to go!
        </CardHabitatLink>
      </p>
    </Card>
  );
};

export default DonationCard;

import { memo } from 'preact/compat';

import { useLiveStreamPlayerContext } from './contexts';
import { useLiveStreamCommandStreamStatsTrigger } from './hooks';

const LiveStreamPlayerStatsTrigger = () => {
  useLiveStreamCommandStreamStatsTrigger(useLiveStreamPlayerContext());
  return null;
};

export default memo(LiveStreamPlayerStatsTrigger);

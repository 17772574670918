import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'preact/compat';

import { cn } from '@/lib/utils';

const ExpandButton = ({ className, ...rest }: Omit<ComponentProps<'button'>, 'children'>) => (
  <button
    type="button"
    className={cn(
      'preflight preflight-button text-2xl leading-none text-white drop-shadow-lg transition-opacity hover:text-grey-5',
      'desktop:absolute desktop:left-0 desktop:top-0 desktop:p-2 desktop:text-base desktop:opacity-0 desktop:group-hover/home-player:opacity-100',
      className,
    )}
    title="Swap mini player content"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} flip="horizontal" />
    <span className="sr-only">Swap mini player content</span>
  </button>
);

export default ExpandButton;

/* eslint-disable react/jsx-props-no-spreading */
import { ComponentProps } from 'preact';

import HabitatLink, { HabitatLinkProps } from '@/components/HabitatLink';
import { cn } from '@/lib/utils';
import { MessageWithHabitat } from '@/queries/community-feed/types';

interface CardHabitatLinkProps extends Omit<HabitatLinkProps, 'habitatId' | 'slug' | 'zooSlug'> {
  message: MessageWithHabitat;
}

const CardLink = ({ className, children, ...rest }: Omit<ComponentProps<'a'>, 'ref'>) => {
  return (
    <a className={cn('preflight preflight-a font-bold', className)} {...rest}>
      {children}
    </a>
  );
};

export const CardHabitatLink = ({ message, className, children, ...rest }: CardHabitatLinkProps) => (
  <HabitatLink
    habitatId={message.habitat._id}
    slug={message.habitat.slug}
    zooSlug={message.zoo.slug}
    className={cn('preflight preflight-a font-bold', className)}
    {...rest}
  >
    {children}
  </HabitatLink>
);

export default CardLink;

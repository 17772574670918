import { useQuery } from '@tanstack/react-query';

import HabitatLink from '@/components/HabitatLink';
import { getHabitatQueryOptions } from '@/queries/habitats';
import { HabitatListItem } from '@/queries/habitats/types';
import { trackMP } from '@/shared/mp';

interface HomeLiveStreamInfoBarProps {
  habitat: HabitatListItem;
}

const HomeLiveStreamInfoBar = ({ habitat }: HomeLiveStreamInfoBarProps) => {
  const { data: conservationText } = useQuery({
    ...getHabitatQueryOptions(habitat?.slug, habitat?.zoo.slug),
    select: (data) => data.habitat.conservationText,
  });

  return (
    <div className="relative z-10 flex bg-primary-green px-4 py-3 text-white mobile:items-center desktop:bg-white desktop:p-0 desktop:text-black desktop:shadow-1">
      <div className="shrink-0 p-4 pb-6 mobile:hidden">
        <div className="relative">
          <img
            className="preflight preflight-img size-16 overflow-hidden rounded-2xl object-contain"
            src={habitat.profileImage}
            alt={`${habitat.title} habitat profile`}
          />
          <div className="absolute -bottom-1/4 -right-1/4 z-10 flex size-12 items-center justify-center overflow-hidden rounded-full bg-white shadow-[0_4px_4px_rgba(0,0,0,0.15)]">
            <img className="preflight preflight-img" src={habitat.zoo.logo} alt={habitat.zoo.name} />
          </div>
        </div>
      </div>
      <div className="min-w-0 grow p-2.5 mobile:hidden">
        <h2 className="preflight preflight-h2 text-lg font-bold">{habitat.title}</h2>
        <p className="preflight preflight-p text-sm/4 text-grey-6">{conservationText}</p>
      </div>
      <h4 className="preflight preflight-h4 min-w-0 grow truncate pr-2 text-sm font-bold desktop:hidden">
        Featured habitat: <span className="text-olive-4">{habitat.title}</span>
      </h4>
      <div className="flex shrink-0 items-center desktop:pr-4">
        <HabitatLink
          habitatId={habitat._id}
          slug={habitat.slug}
          zooSlug={habitat.zoo.slug}
          className="preflight preflight-a rounded-full border border-transparent bg-white px-3 py-1 text-xs font-medium text-black desktop:bg-primary-green desktop:px-4 desktop:py-2 desktop:text-sm desktop:font-medium desktop:text-white desktop:hover:bg-secondary-green"
          onClick={() =>
            trackMP('home-click-on-visit-habitat-button', {
              habitatId: habitat._id,
              habitatName: habitat.title,
              zooName: habitat.zoo.name,
            })
          }
        >
          Visit<span className="mobile:sr-only"> habitat</span>
        </HabitatLink>
      </div>
    </div>
  );
};

export default HomeLiveStreamInfoBar;

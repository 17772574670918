import { isEmpty } from 'lodash-es';
import { ComponentProps, ReactNode } from 'preact/compat';

import { FeedMessageWithMeta, Message, MessageWithMeta } from '@/queries/community-feed/types';

import CardProfile from './CardProfile';
import CardProfileImage from './CardProfileImage';

interface BaseCardProps extends Omit<ComponentProps<'div'>, 'ref'> {
  messageWithMeta: FeedMessageWithMeta;
  headerContent?: ReactNode;
}

export interface CardProps<T extends Message> {
  messageWithMeta: MessageWithMeta<T>;
}

const Card = ({ messageWithMeta, headerContent, children, ...rest }: BaseCardProps) => {
  const { user } = messageWithMeta.message;
  if (isEmpty(user)) return null;
  return (
    <div
      id={messageWithMeta.timetoken.toString()}
      className="relative rounded-lg px-2 py-3 hover:bg-[#f7f7f7] hover:shadow-1"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {headerContent}
      <div className="grid max-w-full grid-cols-[40px_minmax(0,1fr)] gap-2">
        <div>
          <CardProfileImage user={user} />
        </div>
        <div>
          <CardProfile user={user} triggeredAt={messageWithMeta.message.triggeredAt} />
          <div className="mt-1 text-xs text-grey-6">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;

import { useCallback, useEffect, useState } from 'preact/hooks';

export const useLatestActiveDate = () => {
  const [date, setDate] = useState(() => new Date());

  const reload = useCallback(() => {
    setDate(new Date());
  }, []);

  useEffect(() => {
    const handler = () => {
      setDate(new Date());
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, []);

  return [date, reload] as const;
};

export const useLatestActiveTime = () => {
  const [date, setDate] = useState(() => Date.now());

  const reload = useCallback(() => {
    setDate(Date.now());
  }, []);

  useEffect(() => {
    const handler = () => {
      setDate(Date.now());
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  }, []);

  return [date, reload] as const;
};

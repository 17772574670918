import { MessageWithComment, MessageWithMedia, MessageWithQuestion, MessageWithUser } from '@/queries/community-feed/types';

export const getUserURL = (message: MessageWithUser) => `/u/${message.user.username}`;

export const getChatMessageQueryParams = (message: MessageWithComment) => {
  const query = `timetoken=${message.comment.timetoken}`;
  if (message.replyToComment) {
    return `${query}&replyToTimetoken=${message.replyToComment.timetoken}`;
  }
  return query;
};

export const getQuestionQueryParams = (message: MessageWithQuestion) => `card=questions&id=${message.question._id}`;

export const getMediaQueryParams = (message: MessageWithMedia) =>
  `card=album&id=${'media' in message ? message.media._id : message.mediaId}`;

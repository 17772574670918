import { COMMENT_TRUNCATE_LEN_LONG, COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { MediaCommentedMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';
import { getMediaQueryParams } from './utils';

const MediaCommentCreatedCard = ({ messageWithMeta }: CardProps<MediaCommentedMessage>) => {
  const { message } = messageWithMeta;
  const mediaType = 'media' in message ? message.media.type : message.mediaType;
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p line-clamp-3">
        commented &quot;
        <CardHabitatLink message={message} query={getMediaQueryParams(message)}>
          {truncateString(message.comment.text, COMMENT_TRUNCATE_LEN_LONG)}
        </CardHabitatLink>
        &quot; on a {mediaType === 'video' ? 'clip' : 'photo'} of the{' '}
        <CardHabitatLink message={message}>
          {truncateString(message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)}
        </CardHabitatLink>
      </p>
    </Card>
  );
};

export default MediaCommentCreatedCard;

import FeedItem from '@/components/CommunityFeed/FeedItem';
import Loader from '@/components/Loader';
import { useCommunityFeedQuery } from '@/queries/community-feed';

const CommunityFeedCardList = () => {
  const { data, isLoading } = useCommunityFeedQuery(10);

  return isLoading ? (
    <Loader width="40px" height="40px" className="grow" />
  ) : (
    <ul className="preflight preflight-ul min-h-0 grow overflow-y-auto px-2 pb-5 pt-3 after:absolute after:inset-x-0 after:bottom-0 after:h-4 after:rounded-lg after:bg-gradient-to-t after:from-white">
      {data?.map((message) => (
        <li key={message.timetoken.toString()} className="preflight animate-in animate-out fade-in-50 fade-out empty:hidden">
          <FeedItem message={message} />
        </li>
      ))}
    </ul>
  );
};

export default CommunityFeedCardList;

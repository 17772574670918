import {
  DonationMessage,
  FeedMessageWithMeta,
  HabitatCommentedMessage,
  MediaCommentedMessage,
  MediaCreatedMessage,
  MessageWithMeta,
  QuestionCommentedMessage,
  QuestionCreatedMessage,
  SignUpMessage,
  SubscriptionMessage,
} from '@/queries/community-feed/types';

import DonationCard from './DonationCard';
import HabitatCommentedCard from './HabitatCommentedCard';
import MediaCommentCreatedCard from './MediaCommentedCard';
import MediaCreatedCard from './MediaCreatedCard';
import QuestionCommentedCard from './QuestionCommentedCard';
import QuestionCreatedCard from './QuestionCreatedCard';
import SignUpCard from './SignUpCard';
import SubscriptionCard from './SubscriptionCard';

interface FeedItemProps {
  message: FeedMessageWithMeta;
}

const FeedItem = ({ message: messageWithMeta }: FeedItemProps) => {
  return messageWithMeta.message.type === 'sign-up' ? (
    <SignUpCard messageWithMeta={messageWithMeta as MessageWithMeta<SignUpMessage>} />
  ) : messageWithMeta.message.type === 'subscription' ? (
    <SubscriptionCard messageWithMeta={messageWithMeta as MessageWithMeta<SubscriptionMessage>} />
  ) : messageWithMeta.message.type === 'donation' ? (
    <DonationCard messageWithMeta={messageWithMeta as MessageWithMeta<DonationMessage>} />
  ) : messageWithMeta.message.type === 'habitat-commented' ? (
    <HabitatCommentedCard messageWithMeta={messageWithMeta as MessageWithMeta<HabitatCommentedMessage>} />
  ) : messageWithMeta.message.type === 'media-created' ? (
    <MediaCreatedCard messageWithMeta={messageWithMeta as MessageWithMeta<MediaCreatedMessage>} />
  ) : messageWithMeta.message.type === 'media-commented' ? (
    <MediaCommentCreatedCard messageWithMeta={messageWithMeta as MessageWithMeta<MediaCommentedMessage>} />
  ) : messageWithMeta.message.type === 'question-created' ? (
    <QuestionCreatedCard messageWithMeta={messageWithMeta as MessageWithMeta<QuestionCreatedMessage>} />
  ) : messageWithMeta.message.type === 'question-commented' ? (
    <QuestionCommentedCard messageWithMeta={messageWithMeta as MessageWithMeta<QuestionCommentedMessage>} />
  ) : null;
};

export default FeedItem;

import { useMutation } from '@tanstack/react-query';
import { TargetedEvent, useId, useState } from 'preact/compat';
import { useDispatch } from 'react-redux';

import { patchUserProfileMutationFn } from '@/queries/users';
import { useSelector } from '@/redux/helper';
import IconPicker from '@/routes/profile/IconPicker';

import AnimalIcon from '../AnimalIcon';
import { flagAppOnboardingModalStep } from './redux/actions';

const AppOnboardingAvatarMaker = () => {
  const dispatch = useDispatch();
  const id = useId();
  const profile = useSelector((state) => state.user.profile);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [animalIcon, setAnimalIcon] = useState(profile.animalIcon ?? 'animal1');
  const [color, setColor] = useState(profile.color ?? '#FFB145');

  const { mutate, isPending } = useMutation({
    mutationFn: patchUserProfileMutationFn,
    onSuccess: () => {
      dispatch(flagAppOnboardingModalStep('avatarMaker'));
    },
    onError: (error) => {
      setUsernameError((error as any).body?.error ?? 'Something went wrong');
    },
  });

  const validateUsername = (username: string) => {
    const value = username.trim();
    if (value.length < 3) {
      setUsernameError('3+ characters required');
      return false;
    }
    if (value.length > 20) {
      setUsernameError('Max 20 characters');
      return false;
    }
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      setUsernameError('Only letters and numbers are allowed');
      return false;
    }
    setUsernameError('');
    return true;
  };

  const onUsernameChange = (e: TargetedEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value);
    if (!usernameTouched) return;
    validateUsername(e.currentTarget.value);
  };

  const onUsernameBlur = (e: TargetedEvent<HTMLInputElement>) => {
    setUsernameTouched(true);
    validateUsername(e.currentTarget.value);
  };

  const onSubmit = (e: TargetedEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateUsername(username)) {
      return;
    }
    mutate({
      username,
      animalIcon,
      color,
    });
  };

  return (
    <form onSubmit={onSubmit} className="flex h-full flex-col overflow-y-auto">
      <div className="min-h-0 grow overflow-y-auto">
        <div className="flex gap-6 p-6 desktop:px-9 desktop:pt-7">
          <AnimalIcon animalIcon={animalIcon} color={color} width={80} />
          <label htmlFor={`${id}-username`} className="preflight block w-full">
            <span className="mb-3 block text-xs font-medium">Your name or nickname:</span>
            <div
              data-error={!!usernameError}
              className="preflight rounded-lg border p-4 data-[error=true]:border-system-red desktop:max-w-[328px]"
            >
              <input
                id={`${id}-username`}
                type="text"
                name="username"
                placeholder="Username"
                value={username}
                pattern="^[A-Za-z0-9]{3,20}$"
                minLength={3}
                maxLength={20}
                onChange={onUsernameChange}
                onBlur={onUsernameBlur}
                className="preflight preflight-input w-full text-xs"
              />
              {usernameError && <p className="preflight preflight-p -mb-2 text-xs text-system-red">{usernameError}</p>}
            </div>
          </label>
        </div>
        <div className="px-6 pb-6 desktop:px-9">
          <IconPicker
            icon={animalIcon}
            color={color}
            setColor={setColor}
            setIcon={setAnimalIcon}
            className="gap-8 desktop:flex-row desktop:gap-10"
            colorPickerHeader={<h4 className="preflight preflight-h4 mb-4 text-xs font-medium">Select my color</h4>}
            iconPickerHeader={<h4 className="preflight preflight-h4 mb-4 text-xs font-medium">Select my avatar</h4>}
          />
        </div>
      </div>
      <div className="preflight flex flex-col p-6 mobile:pt-0 desktop:flex-row desktop:items-center desktop:border-t desktop:px-9">
        <hr className="preflight preflight-hr desktop:hidden" />
        <div className="grow mobile:my-6">
          <h4 className="preflight preflight-h4 text-base font-bold">This is how zookeepers will recognize you</h4>
        </div>
        <button
          disabled={isPending}
          type="submit"
          className="preflight preflight-button w-full self-center rounded-full bg-primary-green p-4 text-base/none font-bold text-white hover:bg-secondary-green desktop:w-auto desktop:min-w-[200px]"
        >
          Explore
        </button>
      </div>
    </form>
  );
};

export default AppOnboardingAvatarMaker;

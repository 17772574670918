import { Context } from 'preact';
import { useContext } from 'preact/hooks';

const useRequiredContext = <T>(context: Context<T>): NonNullable<T> => {
  const value = useContext(context);
  if (!value) {
    throw new Error(`Cannot find "${context.displayName}"`);
  }
  return value!;
};

export default useRequiredContext;

import { useQuery } from '@tanstack/react-query';
import { RefObject } from 'preact';
import { useRef } from 'preact/hooks';

import HabitatLiveStreamPlayer from '@/components/HabitatLiveStreamPlayer';
import { useHabitatLiveStreamPlayerContext } from '@/components/HabitatLiveStreamPlayer/contexts';
import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import LiveStreamPlayer from '@/components/LiveStreamPlayer';
import { getHabitatQueryOptions } from '@/queries/habitats';
import { HabitatListItem } from '@/queries/habitats/types';

import HomeVideoPlayer from '../HomeVideoPlayer';
import HomeLiveStreamPlayerOverlay from './HomeLiveStreamPlayerOverlay';

interface HomeLiveStreamPlayerProps {
  habitat: HabitatListItem;
  miniMode?: boolean;
  preferLiveStreamMutedRef: RefObject<boolean>;
  onSwitchContentMode?: () => void;
}

const Player = ({
  miniMode,
  preferLiveStreamMutedRef,
  onSwitchContentMode,
}: Pick<HomeLiveStreamPlayerProps, 'miniMode' | 'preferLiveStreamMutedRef' | 'onSwitchContentMode'>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { camera } = useHabitatLiveStreamPlayerContext();
  const isMobile = useIsMobileLayout();

  if (!camera) return <HomeVideoPlayer.Player />;

  return (
    <>
      {camera ? (
        <LiveStreamPlayer.Root streamId={camera.streamKey}>
          <HomeVideoPlayer.Player ref={containerRef}>
            <LiveStreamPlayer.Player />

            <HomeLiveStreamPlayerOverlay
              containerRef={containerRef}
              preferLiveStreamMutedRef={preferLiveStreamMutedRef}
              miniMode={miniMode}
            />
            {!isMobile && miniMode && onSwitchContentMode && <HomeVideoPlayer.ExpandButton onClick={onSwitchContentMode} />}
          </HomeVideoPlayer.Player>

          {/** NOTE: we can add extra player controls outside of the video here */}

          {/* Virtual components for logging */}
          <LiveStreamPlayer.Logger />
          <LiveStreamPlayer.StatsLogger />
          <LiveStreamPlayer.StatsTrigger />
        </LiveStreamPlayer.Root>
      ) : null}
    </>
  );
};

const HomeLiveStreamPlayer = ({
  habitat: selectedHabitat,
  miniMode,
  preferLiveStreamMutedRef,
  onSwitchContentMode,
}: HomeLiveStreamPlayerProps) => {
  const isMobile = useIsMobileLayout();
  const { data: habitat } = useQuery(getHabitatQueryOptions(selectedHabitat?.slug, selectedHabitat?.zoo.slug));

  return (
    <HomeVideoPlayer.Container miniMode={miniMode}>
      {habitat ? (
        <HabitatLiveStreamPlayer.Root habitat={habitat}>
          <Player
            preferLiveStreamMutedRef={preferLiveStreamMutedRef}
            miniMode={miniMode}
            onSwitchContentMode={onSwitchContentMode}
          />
        </HabitatLiveStreamPlayer.Root>
      ) : (
        // As a placeholder when habitat is loading
        <HomeVideoPlayer.Player />
      )}
      {miniMode && isMobile && (
        <div className="flex min-w-0 grow items-center p-2 pr-4 text-white">
          <div className="flex min-w-0 grow flex-col justify-center px-2">
            <h4 className="preflight preflight-h4 text-sm font-bold">{selectedHabitat.title}</h4>
            <span className="text-xs">{selectedHabitat.zoo.name}</span>
          </div>
          <HomeVideoPlayer.ExpandButton onClick={onSwitchContentMode} />
        </div>
      )}
    </HomeVideoPlayer.Container>
  );
};

export default HomeLiveStreamPlayer;

import { createContext, RefObject } from 'preact/compat';

import useRequiredContext from '@/hooks/useRequireContext';

export interface LiveStreamPlayerContextValue {
  iframeRef: RefObject<HTMLIFrameElement>;
  streamId: string;
}

export const LiveStreamPlayerContext = createContext<LiveStreamPlayerContextValue | undefined>(undefined);
LiveStreamPlayerContext.displayName = 'LiveStreamPlayerContext';

export const useLiveStreamPlayerContext = () => useRequiredContext(LiveStreamPlayerContext);

export interface LiveStreamPlayerStateContextValue {
  isIframeLoaded: boolean;
  isVideoLoaded: boolean;
  isPlaying: boolean;
  isMuted: boolean;
  volume: number;
  requestChangePlayingState: (shouldPlay: boolean) => void;
  requestChangeMuteState: (shouldMute: boolean) => void;
  requestChangeVolume: (volume: number) => void;
  requestFullscreenFromIFrame: () => void;
}

export const LiveStreamPlayerStateContext = createContext<LiveStreamPlayerStateContextValue | undefined>(undefined);
LiveStreamPlayerStateContext.displayName = 'LiveStreamPlayerStateContext';

export const useLiveStreamPlayerStateContext = () => useRequiredContext(LiveStreamPlayerStateContext);

import ActionModal from 'Components/ActionModal';
import { NewOutlineButton, PrimaryButton } from 'Components/Buttons';
import ErrorModal from 'Components/modals/Error';
import { setShowPushNotificationModal } from 'Components/NotificationPermissionModal/actions';
import { memo } from 'preact/compat';
import { useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/redux/helper';

import { notificationPermissionStatus, requestNotificationPermission } from '../../helpers';

const NotificationPermissionModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.pushNotification.isOpen);
  const role = useSelector((state) => state.user.role);
  const [showError, setShowError] = useState(false);

  if (!isOpen || role === 'host') {
    return null;
  }

  const onClose = () => dispatch(setShowPushNotificationModal(false));
  const onAllow = async () => {
    const permissionDenied = (await notificationPermissionStatus()) === 'denied';
    if (permissionDenied) {
      setShowError(true);
    } else {
      requestNotificationPermission();
      onClose();
    }
  };

  return (
    <div>
      {showError && (
        <ErrorModal text="It seems you have denied permission to send you push notification already" onClose={onClose} />
      )}
      {!showError && (
        <ActionModal
          onClose={onClose}
          title="Keep up with the animals"
          subTitle="Receive alerts on significant animal updates, exclusive events, newly introduced habitats, and much more!"
          img={<img src="https://zoolife.tv/assets/notification_permission_modal.webp" alt="Notification" />}
          primaryButton={<PrimaryButton size="large" label="Allow" onClick={onAllow} className="w-full" />}
          secondaryButton={<NewOutlineButton size="large" label="Later" onClick={onClose} className="w-full" />}
        />
      )}
    </div>
  );
};

export default memo(NotificationPermissionModal);

import { Ref } from 'preact/hooks';

import { Video } from '@/queries/media/types';

import { PrimaryButton } from '../Buttons';
import { useIsMobileLayout } from '../LayoutDetector/hooks';
import Loader from '../Loader';

interface HighlightsListProps {
  type: string;
  className?: string;
  highlights?: Video[];
  currentHighlightIndex?: number;
  onHighlightClick: (highlight: Video, index: number) => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore?: () => void;
  listRef?: Ref<HTMLUListElement>;
}

const HighlightsList = ({
  type,
  className,
  highlights,
  currentHighlightIndex,
  onHighlightClick,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  listRef,
}: HighlightsListProps) => {
  const isMobile = useIsMobileLayout();

  return (
    <div className={className}>
      <ul ref={listRef} className="preflight preflight-ul grid grid-cols-1 gap-6 desktop:grid-cols-3 desktop:gap-3">
        {highlights?.map((highlight, index) => (
          <li data-group="Highlight List Item" key={highlight._id} data-id={highlight._id} className="mobile:scroll-m-2">
            <a
              data-native
              href={`/highlights?type=${type}&id=${highlight._id}`}
              aria-disabled={highlight.disabled}
              className="preflight preflight-a gap-4 transition-opacity hover:opacity-80 aria-disabled:opacity-50 mobile:grid mobile:grid-cols-[150px_1fr]"
              onClick={(e) => {
                e.preventDefault();
                onHighlightClick(highlight, index);
              }}
            >
              <div className="preflight relative overflow-hidden rounded desktop:rounded-lg">
                <img
                  className="preflight preflight-img aspect-video"
                  src={highlight.thumbnailURL}
                  alt={highlight.title}
                  loading="lazy"
                />
                {currentHighlightIndex === index && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black/75">
                    <div className="flex h-6 items-end gap-0.5 desktop:h-10">
                      <span className="h-[60%] w-2 animate-soundbar bg-white desktop:w-4" />
                      <span className="h-[30%] w-2 animate-soundbar bg-white [animation-delay:-2.2s] desktop:w-4" />
                      <span className="h-[75%] w-2 animate-soundbar bg-white [animation-delay:-3.7s] desktop:w-4" />
                    </div>
                  </div>
                )}
              </div>
              <div className="min-w-0 break-words desktop:hidden">
                <h5 className="preflight preflight-h5 line-clamp-3 text-sm font-semibold">{highlight.title}</h5>
                <p className="preflight preflight-p mt-1 text-xs font-light">
                  {highlight.type === 'curated' ? 'Added by zoolife' : `Taken by @${highlight.username}`}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ul>
      {isFetchingNextPage && <Loader color="var(--charcoal)" className="my-4" width="64px" height="64px" />}
      {hasNextPage && !isFetchingNextPage && (
        <div className="my-4 flex justify-center">
          <PrimaryButton label="Load More" size={isMobile ? 'medium' : 'large'} onClick={onLoadMore} />
        </div>
      )}
    </div>
  );
};

export default HighlightsList;

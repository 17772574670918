import { ReactNode, RefObject, useRef } from 'preact/compat';

import { LiveStreamPlayerContext, LiveStreamPlayerStateContext, useLiveStreamPlayerContext } from './contexts';
import { useLiveStreamPlayerState } from './hooks';

interface LiveStreamPlayerRootProps {
  children: ReactNode;
  streamId: string;
  iframeRef?: RefObject<HTMLIFrameElement>;
}

// This is used for reset the state when changing stream
const InnerRoot = ({ children }: { children: ReactNode }) => {
  const context = useLiveStreamPlayerContext();
  const state = useLiveStreamPlayerState(context);
  return <LiveStreamPlayerStateContext.Provider value={state}>{children}</LiveStreamPlayerStateContext.Provider>;
};

const LiveStreamPlayerRoot = ({ iframeRef: controlledIframeRef, streamId, children }: LiveStreamPlayerRootProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <LiveStreamPlayerContext.Provider key={streamId} value={{ iframeRef: controlledIframeRef || iframeRef, streamId }}>
      <InnerRoot>{children}</InnerRoot>
    </LiveStreamPlayerContext.Provider>
  );
};

export default LiveStreamPlayerRoot;

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';

import Loader from '@/components/Loader';
import { useLatestActiveTime } from '@/hooks/useLatestActiveDateTime';
import { getEventsQueryOptions } from '@/queries/events';

import UpcomingEventItem from './UpcomingEventItem';

const UpcomingEventsCard = () => {
  const [startTime] = useLatestActiveTime();
  const { data, isLoading } = useQuery(getEventsQueryOptions({ startTime, pageSize: 10 }));

  return (
    <div className="preflight relative flex flex-col overflow-hidden rounded-lg border">
      <div className="px-4">
        <div className="preflight flex justify-between border-b pb-3 pt-4">
          <h3 className="preflight preflight-h3 text-sm font-bold">Upcoming events</h3>
          <a href="/schedule" className="preflight preflight-a inline-flex items-center gap-1 text-xs text-grey-3">
            <span>See all</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </div>
      </div>
      {isLoading ? (
        <Loader width="40px" height="40px" className="grow" />
      ) : (
        <div className="preflight min-h-0 grow overflow-y-auto px-2 pb-5 after:absolute after:inset-x-0 after:bottom-0 after:h-4 after:rounded-lg after:bg-gradient-to-t after:from-white">
          <ul className="preflight preflight-ul divide-y px-2">
            {data?.map(
              ({
                _id,
                animal,
                businessHourId,
                habitatId,
                habitatImage,
                habitatSlug,
                zooSlug,
                title,
                description,
                startTime,
                stopTime,
              }) => (
                <li key={_id} className="preflight empty:hidden">
                  <UpcomingEventItem
                    _id={_id}
                    animal={animal}
                    businessHourId={businessHourId}
                    habitatId={habitatId}
                    habitatImage={habitatImage}
                    habitatSlug={habitatSlug}
                    zooSlug={zooSlug}
                    title={title}
                    description={description}
                    startTime={startTime}
                    stopTime={stopTime}
                  />
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UpcomingEventsCard;

import { Box, Text, Tip } from 'grommet';
import { FunctionComponent } from 'preact';
import { memo } from 'preact/compat';

import classPass from './assets/class.svg';
import host from './assets/host.svg';
import lifetime from './assets/lifetime.svg';
import moderator from './assets/moderator.svg';
import monthly from './assets/monthly.svg';
import partner from './assets/partner.svg';
import yearly from './assets/yearly.svg';

const badges = Object.freeze({
  yearly: {
    img: yearly,
    text: 'Annual Member',
  },
  lifetime: {
    img: lifetime,
    text: 'Lifetime Member',
  },
  host: {
    img: host,
    text: 'Animal Expert',
  },
  moderator: {
    img: moderator,
    text: 'Community Moderator',
  },
  monthly: {
    img: monthly,
    text: 'Monthly Member',
  },
  partner: {
    img: partner,
    text: 'Zoo Partner',
  },
  class: {
    img: classPass,
    text: 'Class Member',
  },
});

interface UserBadgeProps {
  badge: string;
  size?: number;
}

const UserBadge: FunctionComponent<UserBadgeProps> = ({ badge, size = 18 }) => {
  if (!badge || !badges[badge as keyof typeof badges]) {
    return null;
  }
  const roundClass = size >= 50 ? 'rounded-xl' : 'rounded-sm';
  return (
    <Tip
      content={
        <Box pad={{ vertical: '5px', horizontal: '8px' }}>
          <Text size="small" weight={700}>
            {badges[badge as keyof typeof badges].text}
          </Text>
        </Box>
      }
      plain
      dropProps={{
        background: 'white',
        align: { bottom: 'top' },
        margin: 'xsmall',
      }}
    >
      <div className="flex items-center justify-center rounded-sm">
        <img
          width={`${size}px`}
          height={`${size}px`}
          className={roundClass}
          src={badges[badge as keyof typeof badges].img}
          alt="badge"
        />
      </div>
    </Tip>
  );
};

export default memo(UserBadge);

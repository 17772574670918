import Player from './HomeVideoPlayer';
import Container from './HomeVideoPlayerContainer';
import ExpandButton from './HomeVideoPlayerExpandButton';

const HomeVideoPlayer = {
  Container,
  Player,
  ExpandButton,
};

export default HomeVideoPlayer;

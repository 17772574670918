import { COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { QuestionCommentedMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';
import { getChatMessageQueryParams, getQuestionQueryParams } from './utils';

const QuestionCommentedCard = ({ messageWithMeta }: CardProps<QuestionCommentedMessage>) => {
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p line-clamp-3">
        responded to a question about the{' '}
        <CardHabitatLink message={messageWithMeta.message}>
          {truncateString(messageWithMeta.message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)}
        </CardHabitatLink>
        :{' '}
        <CardHabitatLink
          message={messageWithMeta.message}
          query={`${getQuestionQueryParams(messageWithMeta.message)}&${getChatMessageQueryParams(messageWithMeta.message)}`}
        >
          {messageWithMeta.message.question.text}
        </CardHabitatLink>
      </p>
    </Card>
  );
};

export default QuestionCommentedCard;

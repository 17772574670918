import { ComponentProps } from 'preact/compat';

import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import { cn } from '@/lib/utils';

interface HomeVideoPlayerContainerProps extends Omit<ComponentProps<'div'>, 'ref'> {
  miniMode?: boolean;
}

/**
 * It is the container of the video player and other related components like controls or info bar under the video.
 * On mobile mini mode, it is styled with `position: fixed` to make it stay in the bottom of the screen.
 * On desktop mini mode, it should only located inside the main player so it stay in the bottom of player.
 */
const HomeVideoPlayerContainer = ({ children, miniMode, className, ...rest }: HomeVideoPlayerContainerProps) => {
  const isMobileLayout = useIsMobileLayout();

  return (
    <div
      data-mini-mode={miniMode}
      className={cn(
        'group/home-player-container flex flex-col',
        miniMode &&
          (isMobileLayout
            ? 'fixed inset-x-0 bottom-[var(--mobileFooterHeight)] z-10 bg-primary-green mobile:flex-row'
            : 'absolute bottom-4 right-4 z-10 w-[25%] min-w-[198px] rounded-lg shadow-lg shadow-black/70 transition-transform group-hover/home-video-section:-translate-y-9'),
        className,
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </div>
  );
};

export default HomeVideoPlayerContainer;

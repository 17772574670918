import { Layer } from 'grommet';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { useSelector } from '@/redux/helper';
import { selectIsFreeUser, selectShouldShowAppOnboardingModal } from '@/redux/selectors/users';

import FreeHabitatsSelector from '../FreeHabitatsSelector';
import AppOnboardingAvatarMaker from './AvatarMaker';
import { flagAppOnboardingModalStep } from './redux/actions';
import AppOnboardingWelcomeMessage from './WelcomeMessage';

const InnerAppOnboarding = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.modals.appOnboardingModal);
  const isFreeUser = useSelector(selectIsFreeUser);

  useEffect(() => {
    if (!isFreeUser) {
      dispatch(flagAppOnboardingModalStep('habitatsSelector'));
    }
  }, [isFreeUser, dispatch]);

  return (
    <Layer className="mobile:h-svh mobile:max-h-full mobile:w-screen mobile:max-w-full mobile:rounded-none desktop:rounded-lg">
      {!state.welcomeMessage ? (
        <AppOnboardingWelcomeMessage />
      ) : !state.avatarMaker ? (
        <AppOnboardingAvatarMaker />
      ) : !state.habitatsSelector && isFreeUser ? (
        <FreeHabitatsSelector />
      ) : null}
    </Layer>
  );
};

const AppOnboarding = () => {
  const showModal = useSelector(selectShouldShowAppOnboardingModal);

  if (!showModal) {
    return null;
  }

  return <InnerAppOnboarding />;
};

export default AppOnboarding;

import { PropsWithChildren } from 'preact/compat';

import CommunityFeedCard from '@/components/CommunityFeedCard';
import { useIsDesktopLayout } from '@/components/LayoutDetector/hooks';
import LiveChannelsBar from '@/components/LiveChannelsBar';
import PubNubWrapper from '@/components/PubNubWrapper';
import UpcomingEventsCard from '@/components/UpcomingEventsCard';

interface HomeLayoutProps extends PropsWithChildren {}

const HomeLayout = ({ children }: HomeLayoutProps) => {
  const isDesktop = useIsDesktopLayout();
  return (
    <div className="relative grid h-full grid-cols-1 overflow-x-hidden fullscreen:block mobile:w-full desktop:grid-cols-[69px_2fr_minmax(340px,1fr)]">
      <aside className="preflight relative z-[11] h-full border-r fullscreen:hidden mobile:hidden">
        {/* We keep the wrapper exists to keep virtual DOM structure as much as possible */}
        {isDesktop && (
          <PubNubWrapper>
            <LiveChannelsBar />
          </PubNubWrapper>
        )}
      </aside>
      <main className="h-full overflow-y-auto">{children}</main>
      <aside className="preflight grid h-full grid-rows-[repeat(2,minmax(300px,1fr))] gap-3 overflow-y-auto border-l p-3 shadow-md fullscreen:hidden mobile:hidden">
        {/* We keep the wrapper exists to keep virtual DOM structure as much as possible */}
        {isDesktop && (
          <>
            <UpcomingEventsCard />
            <CommunityFeedCard />
          </>
        )}
      </aside>
    </div>
  );
};

export default HomeLayout;

import { useCallback, useEffect, useReducer, useRef } from 'preact/hooks';

import { LiveStreamPlayerContextValue } from './contexts';

export type MessageEventData =
  | { key: 'iframeLoaded'; value: boolean; streamId: string }
  | { key: 'iframeVideoLoaded'; value: boolean; streamId: string }
  | { key: 'isPlaying'; value: boolean; streamId: string; tech: string; listener: boolean }
  | { key: 'isMuted'; value: boolean; streamId: string }
  | { key: 'volumeLevel'; value: number | string; streamId: string }
  | { key: 'log'; value: string; streamId: string }
  | { key: 'hls'; value: string; streamId: string }
  | { key: 'logErrorMP'; value: string; streamId: string; error: string; tech: string }
  | { key: 'streamStats'; value: any; streamId: string };

export type LiveStreamPlayerAction =
  | { type: 'setIsIframeLoaded'; payload: boolean }
  | { type: 'setIsVideoLoaded'; payload: boolean }
  | { type: 'setIsPlaying'; payload: boolean }
  | { type: 'setIsMuted'; payload: boolean }
  | { type: 'setVolume'; payload: number }
  | { type: 'reset'; payload?: never };

interface LiveStreamPlayerState {
  isIframeLoaded: boolean;
  isVideoLoaded: boolean;
  isPlaying: boolean;
  isMuted: boolean;
  volume: number;
}

const initialLiveStreamPlayerState: LiveStreamPlayerState = {
  isIframeLoaded: false,
  isVideoLoaded: false,
  isPlaying: false,
  isMuted: true,
  volume: 1,
};

const liveStreamPlayerStateReducer = (
  state: LiveStreamPlayerState,
  { type, payload }: LiveStreamPlayerAction,
): LiveStreamPlayerState => {
  switch (type) {
    case 'setIsIframeLoaded':
      return state.isIframeLoaded === payload ? state : { ...state, isIframeLoaded: payload };
    case 'setIsVideoLoaded':
      return state.isVideoLoaded === payload ? state : { ...state, isVideoLoaded: payload };
    case 'setIsPlaying':
      return state.isPlaying === payload ? state : { ...state, isPlaying: payload };
    case 'setIsMuted':
      return state.isMuted === payload ? state : { ...state, isMuted: payload };
    case 'setVolume':
      return state.volume === payload ? state : { ...state, volume: payload };
    case 'reset':
      return initialLiveStreamPlayerState;
    default:
      return state;
  }
};

export const useLiveStreamPlayerState = ({ streamId, iframeRef }: LiveStreamPlayerContextValue) => {
  const [{ isIframeLoaded, isVideoLoaded, isPlaying, isMuted, volume }, dispatch] = useReducer(
    liveStreamPlayerStateReducer,
    initialLiveStreamPlayerState,
  );

  useEffect(() => {
    const handler = ({ data }: MessageEvent<MessageEventData>) => {
      const { key, value, streamId: id } = data ?? {};
      if (id !== streamId) return;

      switch (key) {
        case 'iframeLoaded':
          dispatch({ type: 'setIsIframeLoaded', payload: value });
          break;

        case 'iframeVideoLoaded':
          dispatch({ type: 'setIsVideoLoaded', payload: value });
          // get the initial isPlaying and isMuted
          iframeRef.current?.contentWindow?.postMessage({ key: 'isPlaying', streamId }, iframeRef.current?.src);
          iframeRef.current?.contentWindow?.postMessage({ key: 'isMuted', streamId }, iframeRef.current?.src);
          break;

        case 'isPlaying':
          dispatch({ type: 'setIsPlaying', payload: value });
          break;

        case 'isMuted':
          dispatch({ type: 'setIsMuted', payload: value });
          break;

        case 'volumeLevel':
          dispatch({ type: 'setVolume', payload: Number(value) });
          break;

        default:
          break;
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [iframeRef, streamId]);

  const requestChangePlayingState = useCallback(
    (val: boolean) => {
      const key = val ? 'play' : 'pause';
      iframeRef.current?.contentWindow?.postMessage({ key, streamId }, iframeRef.current?.src);
    },
    [iframeRef, streamId],
  );

  const requestChangeMuteState = useCallback(
    (val: boolean) => {
      const key = val ? 'mute' : 'unmute';
      iframeRef.current?.contentWindow?.postMessage({ key, streamId }, iframeRef.current?.src);
    },
    [iframeRef, streamId],
  );

  const requestChangeVolume = useCallback(
    (val: number) => {
      iframeRef.current?.contentWindow?.postMessage(
        { key: 'volumeLevel', value: val.toString(), streamId },
        iframeRef.current?.src,
      );
    },
    [iframeRef, streamId],
  );

  const requestFullscreenFromIFrame = useCallback(() => {
    iframeRef.current?.contentWindow?.postMessage({ key: 'fullscreen', streamId }, iframeRef.current?.src);
  }, [iframeRef, streamId]);

  return {
    isIframeLoaded,
    isVideoLoaded,
    isPlaying,
    isMuted,
    volume,
    requestChangePlayingState,
    requestChangeMuteState,
    requestChangeVolume,
    requestFullscreenFromIFrame,
  };
};

export const useLiveStreamEventListener = (streamId: string, callback: (event: MessageEvent<MessageEventData>) => void) => {
  useEffect(() => {
    const handler = (event: MessageEvent<MessageEventData>) => {
      const { streamId: id } = event.data;
      if (streamId !== id) {
        return;
      }

      callback(event);
    };

    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, [callback, streamId]);
};

export const useLiveStreamCommandStreamStatsTrigger = ({ streamId, iframeRef }: LiveStreamPlayerContextValue) => {
  const isPlayingRef = useRef(false);

  useEffect(() => {
    const handler = (event: MessageEvent<MessageEventData>) => {
      const { key, value, streamId: id } = event.data;
      if (id !== streamId) {
        return;
      }
      if (key === 'isPlaying') {
        isPlayingRef.current = value;
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
      isPlayingRef.current = false;
    };
  }, [streamId]);

  useEffect(() => {
    // the first interval is 5 sec, then every 30 seconds thereafter
    let id = setInterval(() => {
      if (!isPlayingRef.current) {
        return;
      }
      iframeRef.current?.contentWindow?.postMessage({ key: 'streamStats', streamId }, iframeRef.current?.src);
      clearInterval(id);
      id = setInterval(() => {
        if (isPlayingRef.current) {
          iframeRef.current?.contentWindow?.postMessage({ key: 'streamStats', streamId }, iframeRef.current?.src);
        }
      }, 30000);
    }, 5000);

    return () => clearInterval(id);
  }, [iframeRef, streamId]);
};

import { ReactNode, useCallback, useContext, useEffect, useState } from 'preact/compat';

import { Camera, Habitat } from '@/queries/habitats/types';
import { useSelector } from '@/redux/helper';
import { GlobalsContext } from '@/shared/context';

import { HabitatLiveStreamPlayerContext } from './contexts';

interface HabitatLiveStreamPlayerRootProps {
  children: ReactNode;
  habitat: Habitat;
}

const HabitatLiveStreamPlayerRoot = ({ habitat, children }: HabitatLiveStreamPlayerRootProps) => {
  const userId = useSelector((state) => state.user.userId);
  const { socket } = useContext(GlobalsContext);

  const [cameraIndex, setCameraIndex] = useState(0);

  const camera = habitat.cameras?.[cameraIndex];
  const streamId = camera?.streamKey;

  const setCamera = useCallback(
    (camera: Camera) => {
      setCameraIndex(Math.max(0, habitat?.cameras?.indexOf(camera) ?? 0));
    },
    [habitat?.cameras],
  );

  useEffect(() => {
    if (socket && userId && habitat?._id) {
      socket.emit('joinRoom', {
        room: habitat?._id,
        userId,
        streamKey: streamId ?? 'offline',
      });
      return () => {
        socket.emit('leaveRoom', { room: habitat?._id });
      };
    }
    return undefined;
  }, [habitat?._id, socket, streamId, userId]);

  useEffect(() => {
    if (socket && userId && camera?._id) {
      socket.emit('joinRoom', {
        room: camera._id,
        userId,
      });
      return () => {
        socket.emit('leaveRoom', { room: camera._id });
      };
    }
    return undefined;
  }, [socket, userId, camera?._id]);

  // reset to first camera when habitat cameras array length changes
  useEffect(() => {
    setCameraIndex(0);
  }, [habitat?.cameras?.length]);

  return (
    <HabitatLiveStreamPlayerContext.Provider value={{ habitat, camera, setCamera }}>
      {children}
    </HabitatLiveStreamPlayerContext.Provider>
  );
};

export default HabitatLiveStreamPlayerRoot;

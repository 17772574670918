import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { AppState, useSelector } from '@/redux/helper';
import { WelcomeMessageLayout } from '@/redux/slices/remoteConfig/types';

import { useIsMobileLayout } from '../LayoutDetector/hooks';
import { flagAppOnboardingModalStep } from './redux/actions';

const selectLayouts = createSelector(
  (state: AppState) => state.user.referralData,
  (state: AppState) => state.remoteConfig.onboardingWelcomeMessageLayouts,
  (state: AppState) => state.remoteConfig.partnerDomainsMap,
  (referralData, layouts, partnerDomainsMap) => {
    if (!layouts) {
      return undefined;
    }
    if (!referralData) {
      return layouts.default;
    }
    if (referralData.utmCampaign && layouts[referralData.utmCampaign]) {
      return layouts[referralData.utmCampaign];
    }
    if (referralData.utmSource && layouts[referralData.utmSource]) {
      return layouts[referralData.utmSource];
    }
    if (referralData.referrer) {
      try {
        const referrerURL = new URL(referralData.referrer);
        const id = partnerDomainsMap?.[referrerURL.hostname];
        if (id && layouts[id]) {
          return layouts[id];
        }
      } catch {}
    }
    return layouts.default;
  },
);

const DEFAULT_LAYOUTS = {
  desktop: {
    foregroundImage: 'https://zoolife.tv/assets/onboarding/desktop.webp',
  } as WelcomeMessageLayout,
  mobile: {
    backgroundImage: 'https://zoolife.tv/assets/onboarding/bg.webp',
    foregroundImage: 'https://zoolife.tv/assets/onboarding/fg.webp',
  } as WelcomeMessageLayout,
};

const AppOnboardingWelcomeMessage = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobileLayout();
  const layouts = useSelector(selectLayouts) ?? DEFAULT_LAYOUTS;
  const layout = layouts[isMobile ? 'mobile' : 'desktop'];

  return (
    <div className="flex h-full flex-col overflow-y-auto desktop:rounded-lg">
      <div
        className="min-h-0 grow overflow-hidden bg-primary-green bg-cover bg-center bg-no-repeat"
        style={layout.backgroundImage ? { backgroundImage: `url(${layout.backgroundImage})` } : undefined}
      >
        {layout.foregroundImage && (
          <img src={layout.foregroundImage} alt="Welcome to zoolife" className="preflight preflight-img max-h-full" />
        )}
      </div>
      <div className="p-6 text-center">
        <button
          type="button"
          className="preflight preflight-button w-full rounded-full bg-primary-green p-4 text-base/none font-bold text-white hover:bg-secondary-green desktop:w-auto desktop:min-w-[200px]"
          onClick={() => dispatch(flagAppOnboardingModalStep('welcomeMessage'))}
        >
          Enter now
        </button>
      </div>
    </div>
  );
};

export default AppOnboardingWelcomeMessage;

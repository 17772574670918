import { COMMENT_TRUNCATE_LEN_LONG, COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { HabitatCommentedMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';
import { getChatMessageQueryParams } from './utils';

const CommentCreatedCard = ({ messageWithMeta }: CardProps<HabitatCommentedMessage>) => {
  const { message } = messageWithMeta;
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p line-clamp-3">
        said:{' '}
        <CardHabitatLink
          message={messageWithMeta.message}
          query={`tab=chat&${getChatMessageQueryParams(messageWithMeta.message)}`}
        >
          {truncateString(message.comment.text, COMMENT_TRUNCATE_LEN_LONG)}
        </CardHabitatLink>{' '}
        in the{' '}
        <CardHabitatLink message={messageWithMeta.message}>
          {truncateString(messageWithMeta.message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)}
        </CardHabitatLink>
      </p>
    </Card>
  );
};

export default CommentCreatedCard;

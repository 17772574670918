import { ComponentProps } from 'preact';

import ControlledCameraSelector from '@/components/CameraSelector/controlled';

import { useHabitatLiveStreamPlayerContext } from '../contexts';

const CameraSelector = ({ renderButton }: Pick<ComponentProps<typeof ControlledCameraSelector>, 'renderButton'>) => {
  const { habitat, camera, setCamera } = useHabitatLiveStreamPlayerContext();

  return (
    <ControlledCameraSelector
      habitatId={habitat._id}
      cameras={habitat.cameras}
      selectedCamera={camera}
      setSelectedCamera={setCamera}
      renderButton={renderButton}
    />
  );
};

export default CameraSelector;

import { PrimaryButton } from 'Components/Buttons';
import ImageSelector from 'Components/ImageSelector';
import Header from 'Components/modals/Header';
import { Box, Form, Heading, Layer, Text, TextInput } from 'grommet';
import { isEmpty } from 'lodash-es';
import { memo } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';
import { API_BASE_URL, buildURL } from 'Shared/fetch';
import useFetch from 'use-http';

import { setShareModalData } from '@/components/ShareModal/shareModalSlice/actions';
import { useSelector } from '@/redux/helper';

import style from './style.scss';

const EXPERT_TALK_FORMATS = ['video/*'];
const MEDIA_FORMATS = ['video/*', 'image/*'];

const MEDIA_TRANSFORM = Object.freeze({
  maxWidth: 1280,
  maxHeight: 720,
  forceAspectRatio: [16, 9],
});

const RECOMMENDED_SETTINGS = {
  ...MEDIA_TRANSFORM,
  fileSize: 100_000_000,
  duration: 600,
};

/**
 * Generates a UploadMediaButton component.
 *
 * @param {object} props - The properties for the component.
 * @param {string} [props.type] - The type of media to upload.
 * @param {string} [props.className] - The type of media to upload.
 * @param {import('preact/compat').ReactNode} [props.label] - The label for the button.
 * @returns {import('preact').JSX.Element} The rendered UploadMediaButton component.
 */
const UploadMediaButton = ({ className, type, label = 'Upload' }) => {
  const habitatId = useSelector((state) => state.habitat.habitatInfo?._id);
  const isExpertTalk = type === 'pastTalks';
  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const mediaRef = useRef();
  const mediaPreviewRef = useRef();
  const { get: getMedia } = useFetch(API_BASE_URL, { credentials: 'include', cachePolicy: 'no-cache' });
  const { post, data, loading, response } = useFetch(
    '',
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
    },
    [showPopup],
  );

  useEffect(() => {
    setTitle('');
    setError('');
    setMedia('');
  }, [showPopup]);

  useEffect(() => {
    // when media is changed, clear errors
    setError('');
  }, [media]);

  const onCloseHandler = () => {
    setShowPopup(false);
  };

  const onSubmit = async () => {
    setError('');
    const isFileValid = await mediaRef.current.validate();
    if (!isFileValid) {
      setError('Invalid file');
      return;
    }

    await post(buildURL('admin/media'), {
      title,
      url: media,
      type,
      habitatId,
    });

    if (!response.ok) {
      setError(response.data?.error ? response.data.error : 'Something went wrong');
    }

    if (response.data?._id) {
      getMedia(`/media/${response.data?._id}`).then(({ media }) => {
        if (!isEmpty(media)) {
          setShowPopup(false);
          dispatch(setShareModalData({ data: media, mediaId: response.data?._id }));
        }
      });
    }
  };

  return (
    <>
      <PrimaryButton
        data="upload"
        size="small"
        label={label}
        onClick={() => setShowPopup(true)}
        margin={{ left: 'auto' }}
        className={className}
      />
      {showPopup && (
        <Layer onClickOutside={onCloseHandler}>
          <Box width="420px" height={{ min: '500px' }}>
            <Header onClose={onCloseHandler}>Upload Media</Header>

            <Box className={style.contentContainer}>
              <Box className={style.contentWrapper}>
                <Box className={style.wrapper}>
                  <Form onSubmit={onSubmit} className={style.form}>
                    <Box margin={{ bottom: '20px' }}>
                      <Heading margin={{ top: '0', bottom: '5px' }} level="5">
                        Media:
                      </Heading>
                      {!isEmpty(media) && (
                        <Box margin={{ bottom: '20px' }}>
                          {media.includes('.mp4') && (
                            <video
                              ref={mediaPreviewRef}
                              src={media}
                              autoPlay
                              onPlay={() => mediaRef.current?.validate()}
                              muted
                              playsInline
                              controls
                            />
                          )}

                          {!media.includes('.mp4') && (
                            <img
                              ref={mediaPreviewRef}
                              src={media}
                              alt=""
                              onLoadedData={() => mediaRef.current?.validate()}
                              className="aspect-video bg-black object-contain"
                            />
                          )}
                        </Box>
                      )}

                      <ImageSelector
                        url={media}
                        ref={mediaRef}
                        previewRef={mediaPreviewRef}
                        placeholder="https://"
                        constraints={{
                          acceptedFormats: isExpertTalk ? EXPERT_TALK_FORMATS : MEDIA_FORMATS,
                        }}
                        recommendedSettings={RECOMMENDED_SETTINGS}
                        transform={MEDIA_TRANSFORM}
                        onChange={(value) => setMedia(value)}
                      />
                    </Box>

                    <Box margin={{ bottom: '20px' }}>
                      <Heading margin="0" level="5">
                        Title:
                      </Heading>
                      <TextInput
                        placeholder="write a short description"
                        value={title}
                        maxLength={100}
                        onChange={({ target: { value } }) => setTitle(value)}
                        className={style.input}
                      />
                    </Box>

                    <Box margin={{ top: 'auto' }}>
                      {error && (
                        <Box alignSelf="center">
                          <Text color="red">{error}</Text>
                        </Box>
                      )}

                      {data?.htmlURL && (
                        <Box alignSelf="center">
                          <Text color="green">Success</Text>
                        </Box>
                      )}

                      <Box alignContent="center" margin={{ top: '20px' }}>
                        <PrimaryButton
                          className={style.button}
                          loading={loading}
                          type="submit"
                          disabled={title.length === 0 || !!data?.htmlURL}
                          size="large"
                          label="Submit"
                        />
                      </Box>
                    </Box>
                  </Form>
                </Box>
              </Box>
            </Box>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default memo(UploadMediaButton);

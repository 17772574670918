import { useRef } from 'preact/hooks';

import { HabitatListItem } from '@/queries/habitats/types';
import { Video } from '@/queries/media/types';

import HomeHighlightVideoPlayer from './HomeHighlightVideoPlayer';
import HomeLiveStreamPlayer from './HomeLiveStreamPlayer';

export type MiniPlayerContentMode = 'live-stream' | 'highlight';

interface HomeVideoSectionProps {
  highlight: Video | undefined;
  preferMuted?: boolean;
  habitat: HabitatListItem | undefined;
  miniPlayerContentMode: MiniPlayerContentMode;
  onNextHighlight: () => void;
  onSwitchContentMode: () => void;
  onCloseMiniPlayer: () => void;
  onMuteChange?: (muted: boolean) => void;
}

const HomeVideoSection = ({
  highlight,
  habitat,
  miniPlayerContentMode,
  preferMuted,
  onNextHighlight,
  onSwitchContentMode,
  onCloseMiniPlayer,
  onMuteChange,
}: HomeVideoSectionProps) => {
  const preferLiveStreamMutedRef = useRef(true);

  return (
    <div className="group/home-video-section preflight relative">
      {highlight && (
        <HomeHighlightVideoPlayer
          highlight={highlight}
          miniMode={miniPlayerContentMode === 'highlight'}
          onSwitchContentMode={onSwitchContentMode}
          onNextHighlight={onNextHighlight}
          onClose={onCloseMiniPlayer}
          muted={preferMuted || miniPlayerContentMode === 'highlight'}
          onMuteChange={onMuteChange}
        />
      )}
      {habitat && (
        <HomeLiveStreamPlayer
          key={habitat._id}
          habitat={habitat}
          miniMode={miniPlayerContentMode === 'live-stream'}
          preferLiveStreamMutedRef={preferLiveStreamMutedRef}
          onSwitchContentMode={onSwitchContentMode}
        />
      )}
    </div>
  );
};

export default HomeVideoSection;

/* eslint-disable react/jsx-props-no-spreading */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCompress, faExpand, faPause, faPlay, faVolume, faVolumeMute } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Grommet, RangeInput, RangeInputExtendedProps } from 'grommet';
import { ComponentProps, RefObject } from 'preact';
import { ReactNode } from 'preact/compat';

import useIsFullscreen, { requestExitFullscreen, requestFullscreen } from '@/hooks/useIsFullscreen';
import { cn } from '@/lib/utils';

export interface IconButtonProps extends Omit<ComponentProps<'button'>, 'ref' | 'children' | 'icon'> {
  label: string;
  icon: IconDefinition;
}

export interface IconButtonWithDefaultProps extends Omit<ComponentProps<'button'>, 'ref' | 'children' | 'icon' | 'value'> {
  label?: string;
  icon?: IconDefinition;
  renderButton?: (props: IconButtonProps) => ReactNode;
}

export interface IconButtonWithBinaryDefaultProps extends IconButtonWithDefaultProps {
  value?: boolean;
}

export interface FullscreenControlButtonProps extends IconButtonWithDefaultProps {
  fullscreenElementRef: RefObject<HTMLElement>;
  onFailToFullscreen?: () => void;
}

export const IconButton = ({ label, icon, className, ...rest }: IconButtonProps) => (
  <button
    type="button"
    className={cn('preflight preflight-button flex size-8 items-center justify-center leading-none', className)}
    {...rest}
  >
    <span className="sr-only">{label}</span>
    <FontAwesomeIcon icon={icon} />
  </button>
);

export const PlayPauseButton = ({ value, renderButton, ...rest }: IconButtonWithBinaryDefaultProps) => {
  const label = value ? 'Pause' : 'Play';
  const icon = value ? faPause : faPlay;
  return renderButton ? <>{renderButton({ label, icon })}</> : <IconButton label={label} icon={icon} {...rest} />;
};

export const MuteControlButton = ({ value, renderButton, ...rest }: IconButtonWithBinaryDefaultProps) => {
  const label = value ? 'Unmute' : 'Mute';
  const icon = value ? faVolumeMute : faVolume;
  return renderButton ? <>{renderButton({ label, icon })}</> : <IconButton label={label} icon={icon} {...rest} />;
};

export const FullscreenControlButton = ({
  fullscreenElementRef,
  onFailToFullscreen,
  renderButton,
  onClick: customOnClick,
  ...rest
}: FullscreenControlButtonProps) => {
  const isFullscreen = useIsFullscreen(fullscreenElementRef);
  const label = isFullscreen ? 'Exit fullscreen mode' : 'Enter fullscreen mode';
  const icon = isFullscreen ? faCompress : faExpand;
  const onClick: typeof customOnClick = (event) => {
    if (isFullscreen) {
      requestExitFullscreen();
    } else {
      requestFullscreen(fullscreenElementRef.current, onFailToFullscreen);
    }
    customOnClick?.(event);
  };

  return renderButton ? (
    <>{renderButton({ label, icon, onClick })}</>
  ) : (
    <IconButton onClick={onClick} label={label} icon={icon} {...rest} />
  );
};

const customThemeRangeInputVolume = {
  global: {
    spacing: '12px',
  },
  rangeInput: {
    track: {
      height: '6px',
      extend: () => `border-radius: 10px`,
      lower: {
        color: 'white',
        opacity: 0.7,
      },
      upper: {
        color: 'white',
        opacity: 0.3,
      },
    },
    thumb: {
      color: 'white',
    },
  },
};

export const VolumeSlider = ({
  wrapperClassName,
  ...props
}: RangeInputExtendedProps & {
  wrapperClassName?: string;
}) => (
  <Grommet theme={customThemeRangeInputVolume} className={wrapperClassName}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <RangeInput min={0} max={1} step={0.1} {...props} />
  </Grommet>
);

interface SoundControlButtonGroupProps extends Omit<ComponentProps<'div'>, 'ref' | 'onVolumeChange'> {
  orientation?: 'vertical' | 'horizontal';
  muteControlButtonClassName?: string;
  volume?: number;
  onVolumeChange?: (val: number) => void;
  isMuted?: boolean;
  onMutedChange?: (val: boolean) => void;
}

export const SoundControlButtonGroup = ({
  orientation = 'horizontal',
  muteControlButtonClassName,
  volume,
  onVolumeChange,
  isMuted,
  onMutedChange,
  className,
  ...rest
}: SoundControlButtonGroupProps) => (
  <div className={cn('group/sound-control relative', className)} {...rest}>
    <MuteControlButton
      className={muteControlButtonClassName}
      value={isMuted}
      onClick={() => {
        onMutedChange?.(!isMuted);
      }}
    />
    <div
      className={clsx(
        'mobile:hidden',
        orientation === 'vertical'
          ? 'pointer-events-none absolute inset-x-0 bottom-full px-1 pb-3 opacity-0 transition-opacity group-hover/sound-control:pointer-events-auto group-hover/sound-control:opacity-100'
          : '',
      )}
    >
      <div
        className={
          orientation === 'vertical'
            ? 'preflight flex h-[76px] items-center justify-center rounded bg-[rgb(69_130_84/.65)] shadow-[0_0_10px_2px_rgba(0,0,0,0.5)]'
            : ''
        }
      >
        <VolumeSlider
          wrapperClassName={orientation === 'vertical' ? '-rotate-90' : ''}
          className={orientation === 'vertical' ? '!w-[60px]' : ''}
          value={volume}
          onChange={(e) => onVolumeChange?.(Number(e.currentTarget.value))}
        />
      </div>
    </div>
  </div>
);

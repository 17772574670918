import { memo } from 'preact/compat';
import { useCallback, useContext } from 'preact/hooks';

import { getDeviceType } from '@/helpers';
import { useSelector } from '@/redux/helper';
import { GlobalsContext } from '@/shared/context';

import { useLiveStreamPlayerContext } from './contexts';
import { MessageEventData, useLiveStreamEventListener } from './hooks';

const LiveStreamPlayerStatsLogger = ({
  extraCallback,
}: {
  extraCallback?: (event: MessageEvent<MessageEventData>) => void;
}) => {
  const { socket } = useContext(GlobalsContext);
  const userId = useSelector((state) => state.user.userId);
  const productId = useSelector((state) => state.user.subscription.productId);
  const active = useSelector((state) => state.user.subscription.active);
  const { streamId } = useLiveStreamPlayerContext();

  const callback = useCallback(
    (event: MessageEvent<MessageEventData>) => {
      const { key, value: data } = event.data;
      if (key !== 'streamStats') {
        return;
      }

      extraCallback?.(event);

      if (data?.streamId) {
        let logData = data;
        // Use -3 for case there is error on log startTime to keep logging
        if ((data?.startTime ?? -1) < 0) {
          logData = {
            ...data,
            startTime: -3,
            lastTime: -3,
          };
        }
        socket?.emit('logWebrtcStats', {
          userId,
          deviceType: getDeviceType(),
          userAgent: navigator.userAgent,
          productId,
          isFreemium: !active,
          ...logData,
        });
      }
    },
    [socket, userId, active, productId, extraCallback],
  );

  useLiveStreamEventListener(streamId, callback);

  return null;
};

export default memo(LiveStreamPlayerStatsLogger);

import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';

import { GlobalsContext } from '@/shared/context';

const getStaticViewers = () => Math.ceil(2 + Math.random() * 5);

const GlobalViewersCount = () => {
  const { socket } = useContext(GlobalsContext);
  const staticViewersCountRef = useRef(0);
  if (staticViewersCountRef.current === 0) {
    staticViewersCountRef.current = getStaticViewers();
  }
  const [viewers, setViewers] = useState(staticViewersCountRef.current);

  useEffect(() => {
    if (!socket) {
      return undefined;
    }

    const handler = ({ count }: { count: number }) => {
      setViewers(count + staticViewersCountRef.current);
    };

    socket.on('connections_count', handler);
    socket.emit('subscribe_connections_count');
    return () => {
      socket.emit('unsubscribe_connections_count');
      socket.off('connections_count', handler);
    };
  }, [socket]);

  return (
    <div className="flex items-center gap-1 text-xs font-medium text-grey-3">
      <FontAwesomeIcon icon={faEye} />
      <span>{viewers}</span>
    </div>
  );
};

export default GlobalViewersCount;

import FallbackContent from './HabitatLiveStreamPlayerFallbackContent';
import PauseContent from './HabitatLiveStreamPlayerPauseContent';
import Root from './HabitatLiveStreamPlayerRoot';

const HabitatLiveStreamPlayer = {
  FallbackContent,
  PauseContent,
  Root,
};

export default HabitatLiveStreamPlayer;

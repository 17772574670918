import { COMMENT_TRUNCATE_LEN_SHORT, truncateString } from '@/helpers';
import { QuestionCreatedMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';
import { CardHabitatLink } from './CardLink';
import { getQuestionQueryParams } from './utils';

const QuestionCreatedCard = ({ messageWithMeta }: CardProps<QuestionCreatedMessage>) => {
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p line-clamp-3">
        left a question for{' '}
        <CardHabitatLink message={messageWithMeta.message}>
          {truncateString(messageWithMeta.message.habitat.title, COMMENT_TRUNCATE_LEN_SHORT)} keepers at{' '}
          {truncateString(messageWithMeta.message.zoo.name, COMMENT_TRUNCATE_LEN_SHORT)}
        </CardHabitatLink>
        :{' '}
        <CardHabitatLink message={messageWithMeta.message} query={getQuestionQueryParams(messageWithMeta.message)}>
          {messageWithMeta.message.question.text}
        </CardHabitatLink>
      </p>
    </Card>
  );
};

export default QuestionCreatedCard;

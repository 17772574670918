/* eslint-disable react/jsx-props-no-spreading */
import { faAngleLeft, faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';
import { ComponentProps } from 'preact';
import { ReactNode } from 'preact/compat';
import { useState } from 'preact/hooks';

import {
  IconButton as PlainIconButton,
  IconButtonProps,
  MuteControlButton as PlainMuteControlButton,
  PlayPauseButton as PlainPlayPauseButton,
} from '@/components/VideoControls/buttons';
import { cn } from '@/lib/utils';

import { useLiveStreamPlayerStateContext } from '../../contexts';

interface OnValueChangeProps<T> {
  onValueChange?: (val: T) => void;
}

interface IconButtonWithDefaultProps extends Omit<IconButtonProps, 'label' | 'icon'> {}

const Container = ({ className, children, ...rest }: Omit<ComponentProps<'div'>, 'ref'>) => (
  <div className={cn('absolute bottom-2 left-2 flex flex-wrap gap-2 text-xs text-white', className)} {...rest}>
    {children}
  </div>
);

const IconButton = ({ className, ...rest }: IconButtonProps) => (
  <PlainIconButton className={clsx('size-7 rounded-full bg-grey-1 hover:opacity-75', className)} {...rest} />
);

const PlayPauseButton = ({ onClick, onValueChange, ...rest }: IconButtonWithDefaultProps & OnValueChangeProps<boolean>) => {
  const { isPlaying, requestChangePlayingState } = useLiveStreamPlayerStateContext();
  return (
    <PlainPlayPauseButton
      value={isPlaying}
      renderButton={({ label, icon }) => (
        <IconButton
          label={label}
          icon={icon}
          onClick={(event) => {
            requestChangePlayingState(!isPlaying);
            onValueChange?.(!isPlaying);
            onClick?.(event);
          }}
          {...rest}
        />
      )}
    />
  );
};

const MuteControlButton = ({
  onClick,
  onValueChange,
  ...rest
}: IconButtonWithDefaultProps & OnValueChangeProps<boolean>) => {
  const { isMuted, requestChangeMuteState } = useLiveStreamPlayerStateContext();
  return (
    <PlainMuteControlButton
      value={isMuted}
      renderButton={({ label, icon }) => (
        <IconButton
          label={label}
          icon={icon}
          onClick={(event) => {
            requestChangeMuteState(!isMuted);
            onValueChange?.(!isMuted);
            onClick?.(event);
          }}
          {...rest}
        />
      )}
    />
  );
};

const ExpandableControls = ({
  renderControls,
  ...rest
}: IconButtonWithDefaultProps & { renderControls: () => ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return isExpanded ? (
    <>
      <IconButton label="Collapse controls" icon={faAngleLeft} onClick={() => setIsExpanded(false)} {...rest} />
      {renderControls()}
    </>
  ) : (
    <IconButton label="Expand controls" icon={faEllipsisVertical} onClick={() => setIsExpanded(true)} {...rest} />
  );
};

const LiveStreamPlayerControlsMinimized = {
  Container,
  IconButton,
  PlayPauseButton,
  MuteControlButton,
  ExpandableControls,
};

export default LiveStreamPlayerControlsMinimized;

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PubNubWrapper from '@/components/PubNubWrapper';

import CommunityFeedCardList from './CommunityFeedCardList';
import GlobalViewersCount from './GlobalViewersCount';

const CommunityFeedCard = () => {
  return (
    <div className="preflight relative flex flex-col overflow-hidden rounded-lg border">
      <div className="px-4">
        <div className="preflight flex justify-between border-b pb-3 pt-4">
          <div className="flex items-center">
            <h3 className="preflight preflight-h3 mr-3 text-sm font-bold">Community feed</h3>
            <GlobalViewersCount />
          </div>
          {false && (
            <a href="/feed" className="preflight preflight-a inline-flex items-center gap-1 text-xs text-grey-3">
              <span>See all</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </a>
          )}
        </div>
      </div>
      <PubNubWrapper>
        <CommunityFeedCardList />
      </PubNubWrapper>
    </div>
  );
};

export default CommunityFeedCard;

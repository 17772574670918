import { useEffect, useState } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import Loader from '../Loader';
import { openContactUsModal } from '../modals/ContactUs/actions';

const LiveStreamPlayerLoadingContent = ({ label }: { label?: string }) => {
  const dispatch = useDispatch();
  const [needRefresh, setNeedRefresh] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNeedRefresh(true);
    }, 15000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="absolute inset-0 flex flex-col items-center justify-center bg-slate-900">
      <div className="relative aspect-square w-full max-w-20 desktop:max-w-40">
        <Loader absolute color="var(--white)" fill width="100%" height="100%" className="!bg-transparent" />
      </div>
      <div className="max-w-[80%] text-white">
        <p className="preflight preflight-p mt-2 text-center text-xl desktop:mt-4 desktop:text-4xl">
          {label ?? 'Hang in there, nature is coming!'}
        </p>
        {needRefresh && (
          <>
            <p className="preflight preflight-p mt-2 text-center text-sm desktop:mt-4">
              <span className="">Not loading? </span>
              <button
                type="button"
                className="preflight preflight-button underline"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Try Refreshing
              </button>
            </p>
            <p className="preflight preflight-p mt-2 text-center text-xs desktop:mt-4">
              Still not working?{' '}
              <button
                type="button"
                className="preflight preflight-button underline"
                onClick={() => dispatch(openContactUsModal())}
              >
                Contact Us
              </button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default LiveStreamPlayerLoadingContent;

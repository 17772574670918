import { buildURL, handleResponse, post } from 'Shared/fetch';

import { isDev } from '../../helpers';

const getMIMETypeFromExtension = (ext) => {
  switch (ext.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'bmp':
      return 'image/bmp';
    case 'svg':
      return 'image/svg+xml';
    case 'webp':
      return 'image/webp';
    case 'mp4':
      return 'video/mp4';
    case 'mov':
      return 'video/quicktime';
    case 'avi':
      return 'video/x-msvideo';
    case 'mpeg':
      return 'video/mpeg';
    case '3gp':
      return 'video/3gpp';
    case '3g2':
      return 'video/3gpp2';
    case 'ogv':
      return 'video/ogg';
    case 'webm':
      return 'video/webm';
    default:
      return undefined;
  }
};

const uploadToApi = async (file, { maxResolution, acceptedFormats, maxFileSize, width, height }) => {
  const url = buildURL('/admin/v2/media');
  const formData = new FormData();

  formData.append('file', file, file.name ?? file.filename);

  if (maxResolution) {
    formData.append('maxResolution', maxResolution);
  }

  if (maxFileSize) {
    formData.append('maxFileSize', maxFileSize);
  }

  if (width) {
    formData.append('width', width);
  }

  if (height) {
    formData.append('height', height);
  }

  acceptedFormats.forEach((format) => {
    formData.append('acceptedFormats[]', format);
  });

  const res = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: { Accept: 'application/json' },
    credentials: 'include',
  });

  return handleResponse(res);
};

/**
 * Uploads a file to an S3 bucket after obtaining a presigned URL.
 *
 * @param {File} file - The file to upload.
 * @param {Object} options - The options for the upload.
 * @return {Promise<Object>} A promise that resolves with the upload response.
 */
const uploadToS3 = async (file, options) => {
  const mimeType = file.type || getMIMETypeFromExtension(file.name.split('.').pop());
  const presignedURLResponse = await post(buildURL('/admin/v2/media/presigned-url'), {
    mimeType,
    filename: file.name || file.filename,
    fileSize: file.size,
    transform: options?.transform,
  });
  const body = new FormData();
  Object.entries(presignedURLResponse.fields).forEach(([key, value]) => {
    body.append(key, value);
  });
  body.append('file', file); // file must be the last field
  const uploadResponse = await fetch(presignedURLResponse.url, {
    method: 'POST',
    body,
  });
  if (!uploadResponse.ok) {
    throw new Error('Upload failed', { cause: await uploadResponse.text() });
  }
  const startedAt = Date.now();
  // Long polling to check if the transform is completed
  while (Date.now() - startedAt < 930_000) {
    // eslint-disable-next-line no-await-in-loop
    const resp = await post(buildURL(`/admin/v2/media/transform`), {
      key: presignedURLResponse.fields.key,
      transform: options?.transform,
    });
    if (!resp?.completed) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 15_000));
      // eslint-disable-next-line no-continue
      continue;
    }
    return resp;
  }
  throw new Error('Transformation of the media took too long.');
};

/**
 * Uploads a file to backend via API or S3 depending on the file size and options.
 *
 * @param {File} file - The file to upload.
 * @param {Object} options - The options for the upload.
 * @return {Promise<Object>} A promise that resolves with the upload response.
 */
export const uploadFile = async (file, options) => {
  const isSvg = (file.type ?? '') === 'image/svg+xml';
  const isOver100MB = (file.size ?? 0) > 100 * 1024 * 1024;
  if ((!options.transform && !isOver100MB) || isSvg) {
    return uploadToApi(file, options);
  }
  return uploadToS3(file, options);
};

export const fromUrlToS3 = (url) => {
  const apiUrl = buildURL('/admin/media/fromurl');
  return post(apiUrl, { url });
};

export const getHeaders = async (url, origin) => {
  let URI = url;

  // we remove origin in development because we have a proxy for /assets/**
  if (isDev()) {
    URI = URI.replace(origin, '');
  }
  const res = await fetch(URI, {
    method: 'HEAD',
  });

  return res.headers;
};

import { RefObject } from 'preact';
import { useEffect, useState } from 'preact/hooks';

export const requestExitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document as any).webkitExitFullscreen) {
    /* Safari */
    (document as any).webkitExitFullscreen();
  } else if ((document as any).msExitFullscreen) {
    /* IE11 */
    (document as any).msExitFullscreen();
  }
};

export const requestFullscreen = (target: HTMLElement | null, fallback?: () => void) => {
  if (!target) return;
  if (target.requestFullscreen) {
    target.requestFullscreen();
  } else if ((target as any).webkitRequestFullscreen) {
    /* Safari */
    (target as any).webkitRequestFullscreen();
  } else if ((target as any).msRequestFullscreen) {
    /* IE11 */
    (target as any).msRequestFullscreen();
  } else if ((target as any)?.webkitSupportsFullscreen) {
    /* Mobile Safari on video target */
    (target as any).webkitEnterFullScreen();
  } else {
    // Fallback for Safari with no support for full screen for none video elements
    const video = target.querySelector('video');
    if ((video as any)?.webkitSupportsFullscreen) {
      (video as any).webkitEnterFullScreen();
    } else {
      fallback?.();
    }
  }
};

export const isElementFullscreen = (element: HTMLElement | null) => {
  if (!element || typeof window === 'undefined') return false;
  const fullscreenElement = document.fullscreenElement ?? (document as any).webkitCurrentFullScreenElement;
  return fullscreenElement === element || element.contains(fullscreenElement);
};

const useIsFullscreen = (elementRef: RefObject<HTMLElement>) => {
  const [isFullscreen, _setIsFullscreen] = useState(() => isElementFullscreen(elementRef.current));

  useEffect(() => {
    const onFullscreenChange = () => {
      _setIsFullscreen(isElementFullscreen(elementRef.current));
    };

    // trigger it once to sync the initial value
    onFullscreenChange();

    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', onFullscreenChange);
    };
  }, [elementRef]);

  return isFullscreen;
};

export default useIsFullscreen;

import { RadioButtonGroup } from 'grommet';
import { ComponentProps, ReactNode } from 'preact/compat';
import { useMemo } from 'preact/hooks';

import RoundButton from '@/components/RoundButton';
import { Camera } from '@/queries/habitats/types';
import { useSelector } from '@/redux/helper';
import { buildURL } from '@/shared/fetch';

import style from './style.scss';
import switchCamera from './switchCamera.svg';

interface CameraSelectorProps {
  habitatId?: string;
  cameras?: Camera[];
  selectedCamera?: Camera;
  setSelectedCamera?: (camera: Camera) => void;
  renderButton?: ({ onClick }: { onClick: () => void }) => ReactNode;
}

const CameraSelector = ({ renderButton, habitatId, cameras, selectedCamera, setSelectedCamera }: CameraSelectorProps) => {
  const userId = useSelector((state) => state.user.userId);

  const needsSelectCamera = (cameras?.length ?? 0) > 1;

  const availableCameras = useMemo(
    () => cameras?.map(({ _id, cameraName }) => ({ label: cameraName, value: _id })),
    [cameras],
  );

  const onChangeHandler: ComponentProps<'input'>['onChange'] = ({ currentTarget }) => {
    const cameraId = currentTarget.value;
    const camera = cameras?.find(({ _id }) => _id === cameraId);
    if (camera) {
      setSelectedCamera?.(camera);
    }
  };

  const currentCameraId = useMemo(() => {
    if (!needsSelectCamera) {
      return undefined;
    }
    const currentCamera = cameras?.find(({ _id }) => _id === selectedCamera?._id);
    return currentCamera?._id || cameras?.[0]._id;
  }, [needsSelectCamera, selectedCamera, cameras]);

  const clickHandler = () => {
    fetch(buildURL('/log'), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: 'CameraSwitch',
        userId,
        payload: { habitatId },
      }),
    });
  };

  if (!needsSelectCamera || !availableCameras || !currentCameraId) {
    return null;
  }

  return (
    <div className="group/camera-selector relative">
      {renderButton ? (
        renderButton({ onClick: clickHandler })
      ) : (
        <RoundButton
          width={28}
          backgroundColor="var(--hunterGreenMediumDark)"
          color="white"
          className={style.cameraButton}
          onClick={clickHandler}
        >
          <img src={switchCamera} alt="camera selector" className="preflight preflight-img w-5" />
        </RoundButton>
      )}

      <div className="pointer-events-none absolute bottom-full left-1/2 -translate-x-1/2 pb-2 opacity-0 transition-opacity group-hover/camera-selector:pointer-events-auto group-hover/camera-selector:opacity-100">
        <div className="min-w-max max-w-64 rounded-2xl bg-grey-1 px-3 py-2 text-white">
          <h3 className="preflight preflight-h3 mb-2 text-base font-bold">Switch Camera:</h3>
          <RadioButtonGroup
            name="selectedStream"
            options={availableCameras}
            value={currentCameraId}
            onChange={onChangeHandler}
            className={style.radioContainer}
          />
        </div>
      </div>
    </div>
  );
};

export default CameraSelector;

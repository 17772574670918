import { ComponentProps } from 'preact';

const HabitatLiveStreamPlayerFallbackContent = ({
  children,
  ...props
}: Omit<ComponentProps<'div'>, 'ref' | 'className'>) => {
  return (
    <div
      className="preflight absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-r from-[#f8b8d0] via-[#827ea8] to-[#7b8bc6] p-2 text-white"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </div>
  );
};

export default HabitatLiveStreamPlayerFallbackContent;

import { MinimalUser } from '@/queries/community-feed/types';

import AnimalIcon from '../../AnimalIcon';
import CardLink from './CardLink';

const CardProfileImage = ({ user }: { user?: MinimalUser }) => {
  return user ? (
    <CardLink href={`/u/${user.username}`}>
      <AnimalIcon animalIcon={user.profile.animalIcon} color={user.profile.color} width={40} />
    </CardLink>
  ) : (
    <AnimalIcon animalIcon="animal4" color="#FF8A00" width={40} />
  );
};

export default CardProfileImage;

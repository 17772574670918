import { faArrowRight, faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import HabitatLink from '@/components/HabitatLink';

interface Props {
  image: string;
  animal: string;
  habitatId: string;
  habitatSlug: string;
  zoo: string;
  zooSlug: string;
  liveTalk: boolean;
  isFree: boolean;
  isFavorited: boolean;
}

const LiveHabitatCard = ({ isFree, image, animal, habitatId, habitatSlug, zoo, zooSlug, liveTalk, isFavorited }: Props) => {
  return (
    <HabitatLink
      className="preflight preflight-a block min-h-[70px] w-[255px] p-2"
      habitatId={habitatId}
      slug={habitatSlug}
      zooSlug={zooSlug}
    >
      <div className="flex items-center rounded bg-white p-2 shadow-sm hover:shadow-lg">
        <div className="relative mr-4 flex flex-col justify-center">
          <img className="preflight preflight-img h-12 w-12 rounded-full" src={image} alt="animal" />
          {isFavorited && (
            <FontAwesomeIcon className="absolute right-0 top-0" icon={faHeart} color="var(--pink)" size="xs" spin={false} />
          )}

          {liveTalk ? (
            <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 rounded-md bg-primary-yellow p-1 text-xs font-medium leading-none text-grey-1">
              Event
            </span>
          ) : (
            isFree && (
              <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 rounded-md bg-secondary-green p-1 text-xs font-medium leading-none text-white">
                Free
              </span>
            )
          )}
        </div>
        <div className="grow">
          <div className="mb-1 text-[13px] font-medium text-grey-1">{animal}</div>
          <div className="text-[12px] text-grey-3">{zoo}</div>
        </div>
        <div className="mr-2">
          <FontAwesomeIcon icon={faArrowRight} color="var(--mediumGrey)" />
        </div>
      </div>
    </HabitatLink>
  );
};

export default LiveHabitatCard;

import { faHeart, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lazy, Suspense } from 'preact/compat';
import { useRef, useState } from 'preact/hooks';

import HabitatLink from '@/components/HabitatLink';
import { HabitatListItem } from '@/queries/habitats/types';

interface Props {
  habitat: HabitatListItem & { isFree: boolean; isFavorited: boolean };
}

const ShareModal = lazy(() => import('@/components/ShareModal/Standalone'));

const HabitatIcon = ({
  habitat: {
    _id,
    profileImage,
    slug: habitatSlug,
    title,
    zoo: { slug: zooSlug, name: zooName },
    liveTalk,
    trailer,
    isFree,
    isFavorited,
  },
}: Props) => {
  const [isOverTip, setIsOverTip] = useState(false);
  const [isOverImage, setIsOverImage] = useState(false);
  const [showTrailer, setShowTrailer] = useState(false);
  const parentRef = useRef<HTMLDivElement>(null);

  const onMouseEnterImage = () => {
    setIsOverImage(true);
  };
  const onMouseLeaveImage = () => {
    setIsOverImage(false);
  };

  const onMouseEnterTip = () => {
    setIsOverTip(true);
  };
  const onMouseLeaveTip = () => {
    setIsOverTip(false);
  };

  const getTipPosition = () => {
    const rect = parentRef.current?.getBoundingClientRect();
    if (!rect) {
      return undefined;
    }
    return {
      top: `${rect.y}px`,
      left: `${rect.x + rect.width}px`,
    };
  };

  const onPressTrailerButton = () => {
    setIsOverTip(false);
    setIsOverImage(false);
    setShowTrailer(true);
  };

  return (
    <div
      className="relative z-10 flex items-center justify-center"
      onMouseEnter={onMouseEnterImage}
      onMouseLeave={onMouseLeaveImage}
      ref={parentRef}
    >
      <HabitatLink className="relative" habitatId={_id} slug={habitatSlug} zooSlug={zooSlug}>
        <img className="h-12 w-12 rounded-full" src={profileImage} alt={title} />
        {isFavorited && (
          <FontAwesomeIcon className="absolute right-0 top-0" icon={faHeart} color="var(--pink)" size="xs" spin={false} />
        )}
        {liveTalk ? (
          <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 rounded-md bg-primary-yellow p-1 text-xs font-medium leading-none text-grey-1">
            Event
          </span>
        ) : (
          isFree && (
            <span className="absolute -bottom-1 left-1/2 -translate-x-1/2 rounded-md bg-secondary-green p-1 text-xs font-medium leading-none text-white">
              Free
            </span>
          )
        )}
      </HabitatLink>
      {(isOverTip || isOverImage) && (
        <div
          className="fixed z-10 flex whitespace-nowrap drop-shadow"
          onMouseEnter={onMouseEnterTip}
          onMouseLeave={onMouseLeaveTip}
          style={getTipPosition()}
        >
          <div className="h-0 w-0 self-center border-[8px] border-solid border-transparent border-r-white" />
          <div className="flex items-center rounded-md bg-white p-2 text-left text-[9px] font-medium leading-4">
            {title}
            <br />
            {`@ ${zooName}`}
            {trailer && (
              <button
                type="button"
                className="preflight preflight-button ml-1 flex items-center rounded-3xl bg-primary-green px-3 py-2 text-white"
                onClick={() => onPressTrailerButton()}
              >
                <FontAwesomeIcon icon={faPlay} size="sm" />
                <span className="pl-1 text-xs font-bold">Trailer</span>
              </button>
            )}
          </div>
        </div>
      )}
      {showTrailer && trailer && (
        <Suspense fallback={null}>
          <ShareModal
            open
            data={trailer}
            onClose={() => setShowTrailer(false)}
            mediaId={trailer._id}
            isDownloadAllowed={false}
            isTrailer
          />
        </Suspense>
      )}
    </div>
  );
};

export default HabitatIcon;

import { createContext } from 'preact';

import useRequiredContext from '@/hooks/useRequireContext';
import { Camera, Habitat } from '@/queries/habitats/types';

interface HabitatLiveStreamPlayerContextValue {
  habitat: Habitat;
  camera?: Camera;
  setCamera: (camera: Camera) => void;
}

export const HabitatLiveStreamPlayerContext = createContext<HabitatLiveStreamPlayerContextValue | undefined>(undefined);

export const useHabitatLiveStreamPlayerContext = () => useRequiredContext(HabitatLiveStreamPlayerContext);

import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentProps } from 'preact';

const HabitatLiveStreamPlayerPauseContent = (props: Omit<ComponentProps<'button'>, 'ref' | 'className'>) => {
  return (
    <button
      type="button"
      className="preflight preflight-button absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-r from-[#f8b8d0] via-[#827ea8] to-[#7b8bc6] p-2"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <FontAwesomeIcon icon={faPlay} color="white" className="text-[24px] desktop:text-[64px]" />
      <p className="preflight preflight-p max-w-[400px] text-center text-white desktop:text-lg desktop:font-bold">
        <span className="mb-1 mt-3 inline-block text-xs desktop:mb-6 desktop:mt-12 desktop:text-2xl">Stream Paused</span>
        <br />
        <span className="text-[10px] desktop:text-base">Click to resume playing.</span>
      </p>
    </button>
  );
};

export default HabitatLiveStreamPlayerPauseContent;

import { SubscriptionMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';

const SubscriptionCard = ({ messageWithMeta }: CardProps<SubscriptionMessage>) => {
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p">
        just became a paid member, <b>hooray!</b>
      </p>
    </Card>
  );
};

export default SubscriptionCard;

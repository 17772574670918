import { hashKey } from '@tanstack/react-query';
import { memoize } from 'lodash-es';

const toReadableBytes = (bytes: number) =>
  bytes < 1000 ? `${bytes}B` : bytes < 1_000_000 ? `${bytes / 1000}KB` : `${bytes / 1_000_000}MB`;

const toReadableTime = (seconds: number) =>
  seconds < 60 ? `${seconds}s` : `${Math.floor(seconds / 60)}min${seconds % 60 ? `${seconds % 60}s` : ''}`;

// eslint-disable-next-line import/prefer-default-export
export const makeHelpText = memoize(
  (transform: {
    maxWidth?: number;
    maxHeight?: number;
    forceAspectRatio?: [number, number];
    fileSize?: number;
    duration?: number;
    outputFormat?: string;
  }) =>
    [
      transform.maxWidth && transform.maxHeight
        ? `Resolution: ${transform.maxWidth}×${transform.maxHeight}`
        : transform.maxWidth
        ? `Width: ${transform.maxWidth}px`
        : transform.maxHeight
        ? `Height: ${transform.maxHeight}px`
        : null,
      transform.forceAspectRatio && `Aspect ratio: ${transform.forceAspectRatio[0]}:${transform.forceAspectRatio[1]}`,
      transform.fileSize && `File size: ${toReadableBytes(transform.fileSize)}`,
      transform.duration && `Duration: ≤${toReadableTime(transform.duration)}`,
      transform.outputFormat && `Format: ${transform.outputFormat}`,
    ].filter(Boolean) as string[],
  (transform) => hashKey([transform]),
);

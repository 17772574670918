import { useEffect, useState } from 'preact/compat';

import UserBadge from '@/components/UserBadge';
import { formatTimeAgoOrTimestamp } from '@/helpers';
import { MinimalUser } from '@/queries/community-feed/types';

import CardLink from './CardLink';

interface CardProfileProps {
  user?: MinimalUser;
  triggeredAt: string;
}

const CardProfile = ({ user, triggeredAt }: CardProfileProps) => {
  const [messageTime, setMessageTime] = useState(() => formatTimeAgoOrTimestamp(new Date(triggeredAt), true));

  useEffect(() => {
    const now = Date.now();
    const date = Date.parse(triggeredAt);
    // don't update time message if the time is older than 24 hours
    if (date < now - 24 * 60 * 60 * 1000) {
      return undefined;
    }

    const interval = (date < now - 60 * 60 * 1000 ? 60 * 60 : 60) * 1000;

    const id = setInterval(() => {
      setMessageTime(formatTimeAgoOrTimestamp(new Date(triggeredAt), true));
    }, interval);
    return () => clearInterval(id);
  }, [triggeredAt]);

  return (
    <div className="relative flex items-center justify-between gap-2">
      <div className="flex min-w-0 grow flex-wrap items-center gap-1">
        {user ? (
          <CardLink
            href={`/u/${user.username}`}
            className="mr-0.5 overflow-hidden text-ellipsis text-sm font-semibold leading-4"
          >
            {user.username}
          </CardLink>
        ) : (
          <span className="preflight mr-0.5 overflow-hidden text-ellipsis text-sm font-semibold leading-4">zoolife</span>
        )}
        {user?.badges?.map((badge) => <UserBadge key={badge} badge={badge} />)}
      </div>
      <span className="shrink-0 text-[10px] leading-4 text-grey-1 opacity-60">{messageTime}</span>
    </div>
  );
};

export default CardProfile;

import clsx from 'clsx';
import { ComponentProps } from 'preact';

import { useSelector } from '@/redux/helper';

import { useLiveStreamPlayerContext } from './contexts';

interface LiveStreamPlayerProps extends Omit<ComponentProps<'iframe'>, 'ref' | 'src'> {}

export const streamingAppName = process.env.PREACT_APP_MEDIASERVER_SOCKET_URL?.includes('WebRTCAppEE')
  ? 'WebRTCAppEE'
  : 'Development';

const LiveStreamPlayer = ({ className, ...rest }: LiveStreamPlayerProps) => {
  const userId = useSelector((state) => state.user.userId);
  const { streamId, iframeRef } = useLiveStreamPlayerContext();

  return (
    <iframe
      className={clsx('preflight preflight-iframe size-full', className)}
      allowFullScreen
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      title="zoolife's live stream player"
      data-hj-allow-iframe
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={iframeRef}
      src={`https://brizi.video/${streamingAppName}/iframe.html?id=${streamId}&subscriberId=${userId}&playOrder=webrtc,hls`}
    />
  );
};

export default LiveStreamPlayer;

import { noop } from 'lodash-es';
import { memo } from 'preact/compat';
import { useCallback, useEffect, useState } from 'preact/hooks';

import { useSelector } from '@/redux/helper';
import { buildURL } from '@/shared/fetch';
import { logGAEvent } from '@/shared/ga';
import { trackMP } from '@/shared/mp';

import { useLiveStreamPlayerContext } from './contexts';
import { MessageEventData, useLiveStreamEventListener } from './hooks';
import { formatData } from './utils';

const LiveStreamPlayerLogger = () => {
  const userId = useSelector((state) => state.user.userId);
  const { streamId } = useLiveStreamPlayerContext();

  const [isPlaying, setIsPlaying] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [isIframeVideoLoaded, setIsIframeVideoLoaded] = useState(false);

  // only handle logging related events, which should be initialized once
  const callback = useCallback(
    ({ data }: MessageEvent<MessageEventData>) => {
      const { key, value } = data;

      if (key === 'log') {
        logGAEvent('webrtc-iframe', value, `${streamId}-${userId}`);
      }

      if (key === 'hls') {
        fetch(buildURL('/hls/log'), {
          method: 'POST',
          body: JSON.stringify({ userAgent: navigator.userAgent, streamId, userId }),
          headers: { 'content-type': 'application/json' },
        }).catch(noop);
      }

      if (key === 'logErrorMP') {
        trackMP('iframe-error', formatData(data));
      }

      if (key === 'isPlaying') {
        setIsPlaying(value);
        if (data.listener === true) {
          trackMP('iframe-is-playing', formatData(data));
        }
      }

      if (key === 'iframeLoaded') {
        setIsIframeLoaded(value);
      }

      if (key === 'iframeVideoLoaded') {
        setIsIframeVideoLoaded(value);
      }
    },
    [streamId, userId],
  );

  useEffect(() => {
    if (isPlaying && isIframeLoaded && isIframeVideoLoaded) {
      trackMP('iframe-success', formatData({ streamId }));
    }
  }, [isPlaying, isIframeLoaded, isIframeVideoLoaded, streamId]);

  useLiveStreamEventListener(streamId, callback);

  return null;
};

export default memo(LiveStreamPlayerLogger);

import Player from './LiveStreamPlayer';
import Logger from './LiveStreamPlayerLogger';
import MessageListener from './LiveStreamPlayerMessageListener';
import Root from './LiveStreamPlayerRoot';
import StatsLogger from './LiveStreamPlayerStatsLogger';
import StatsTrigger from './LiveStreamPlayerStatsTrigger';

const LiveStreamPlayer = {
  Player,
  MessageListener,
  Logger,
  Root,
  StatsLogger,
  StatsTrigger,
};

export default LiveStreamPlayer;

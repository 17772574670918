import { Layer } from 'grommet';

import { useSelector } from '@/redux/helper';
import { selectIsFreeUser } from '@/redux/selectors/users';

import FreeHabitatsSelector from '.';

const FreeHabitatSelectorModal = () => {
  const logged = useSelector((state) => state.user.logged);
  const isFreeUser = useSelector(selectIsFreeUser);
  const isAppOnboarded = useSelector((state) => state.user.isAppOnboarded);
  const freeHabitatsLength = useSelector((state) => state.user.subscription.freeHabitats?.length ?? 0);
  const maximumNumberOfFreeHabitats = useSelector((state) => state.user.maximumNumberOfFreeHabitats);

  if (!logged || !isFreeUser || !isAppOnboarded || maximumNumberOfFreeHabitats <= freeHabitatsLength) {
    return null;
  }

  return (
    <Layer className="mobile:h-svh mobile:max-h-full mobile:w-screen mobile:max-w-full mobile:rounded-none desktop:rounded-lg">
      <FreeHabitatsSelector />
    </Layer>
  );
};

export default FreeHabitatSelectorModal;

import { memo } from 'preact/compat';

import { useLiveStreamPlayerContext } from './contexts';
import { MessageEventData, useLiveStreamEventListener } from './hooks';

const LiveStreamPlayerMessageListener = ({ callback }: { callback: (event: MessageEvent<MessageEventData>) => void }) => {
  const { streamId } = useLiveStreamPlayerContext();

  useLiveStreamEventListener(streamId, callback);

  return null;
};

export default memo(LiveStreamPlayerMessageListener);

import { useTour } from '@reactour/tour';
import { PopoverContentProps } from '@reactour/tour/dist/types';
import { createSelector } from '@reduxjs/toolkit';
import { useEffect } from 'preact/hooks';
import { useDispatch } from 'react-redux';

import { PrimaryButton } from '@/components/Buttons';
import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import { AppState, useSelector } from '@/redux/helper';
import { selectIsFreeUser, selectShouldShowAppOnboardingModal } from '@/redux/selectors/users';
import { setUserData } from '@/redux/slices/user/actions';
import { buildURL, get, post } from '@/shared/fetch';
import { trackMP } from '@/shared/mp';

const selectShouldShowTour = createSelector(
  selectShouldShowAppOnboardingModal,
  (state: AppState) => state.user.isAppOnboarded,
  (shouldShowAppOnboardingModal, isAppOnboarded) => !shouldShowAppOnboardingModal && !isAppOnboarded,
);

const popoverStyle = (props: { [key: string]: any }) => ({
  ...props,
  maxWidth: '304px',
  borderRadius: '16px',
  padding: '24px 32px',
});

const TourContent = ({ steps, currentStep, setCurrentStep }: PopoverContentProps) => {
  const dispatch = useDispatch();
  const step = steps[currentStep];
  return (
    <div className="flex flex-col justify-center gap-6">
      <h4 className="preflight preflight-h4 text-center text-xl font-bold">{step.content}</h4>
      <PrimaryButton
        label="Got it!"
        onClick={() => {
          setCurrentStep(currentStep + 1);
          if (steps.length - 1 === currentStep) {
            post(buildURL('/users/steps'), { step: 'isAppOnboarded', value: true })
              .then(() => get(buildURL('/users/user')))
              .then((user) => user && dispatch(setUserData(user)));
            trackMP('onboarding-step', {
              step: 3, // This is the final step 0-base index of the onboarding
              place: 'app-onboarding',
              isCompleted: true,
            });
          }
        }}
      />
    </div>
  );
};

const SpotlightFreeHabitats = () => {
  const showTour = useSelector(selectShouldShowTour);
  const isFreeUser = useSelector(selectIsFreeUser);
  const isMobile = useIsMobileLayout();
  const { setIsOpen, setSteps } = useTour();

  useEffect(() => {
    if (!showTour) {
      setIsOpen(false);
      setSteps([]);
      return undefined;
    }

    const id = setTimeout(
      () => {
        const content = isFreeUser ? 'Your free interactive habitats are here!' : 'Your interactive habitats are here!';
        const selector = isMobile
          ? '[data-tour-id="habitatsTab"]'
          : isFreeUser
          ? '[data-tour-id="liveChannelsBarFreeHabitats"]'
          : '[data-tour-id="liveChannelsBarHabitats"]';
        const position = isMobile ? 'top' : 'left';

        setSteps([{ content, selector, position, styles: { popover: popoverStyle } }]);
        setIsOpen(true);
      },
      isMobile ? 0 : 500, // delay for desktop because of the layout change
    );

    return () => clearTimeout(id);
  }, [isFreeUser, isMobile, setIsOpen, setSteps, showTour]);

  return null;
};

SpotlightFreeHabitats.TourContent = TourContent;

export default SpotlightFreeHabitats;

/* eslint-disable react/jsx-props-no-spreading */
import { ComponentProps } from 'preact';

import {
  MuteControlButton as PlainMuteControlButton,
  PlayPauseButton as PlainPlayPauseButton,
  SoundControlButtonGroup as PlainSoundControlButtonGroup,
} from '@/components/VideoControls/buttons';
import { cn } from '@/lib/utils';

import { useLiveStreamPlayerStateContext } from '../../contexts';

const Container = ({ className, children, ...rest }: Omit<ComponentProps<'div'>, 'ref'>) => (
  <div
    className={cn(
      'absolute inset-x-0 bottom-0 flex bg-[rgb(69_130_84/.65)] p-1 text-[22px] text-white desktop:grid desktop:grid-cols-3',
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

const ButtonsGroup = ({ className, children, ...rest }: Omit<ComponentProps<'div'>, 'ref'>) => (
  <div className={cn('flex gap-2', className)} {...rest}>
    {children}
  </div>
);

interface OnValueChangeProps<T> {
  onValueChange?: (val: T) => void;
}

const PlayPauseButton = ({
  onClick,
  onValueChange,
  ...rest
}: ComponentProps<typeof PlainPlayPauseButton> & OnValueChangeProps<boolean>) => {
  const { isPlaying, requestChangePlayingState } = useLiveStreamPlayerStateContext();
  return (
    <PlainPlayPauseButton
      value={isPlaying}
      onClick={(event) => {
        requestChangePlayingState(!isPlaying);
        onValueChange?.(!isPlaying);
        onClick?.(event);
      }}
      {...rest}
    />
  );
};

const MuteControlButton = ({
  onClick,
  onValueChange,
  ...rest
}: ComponentProps<typeof PlainPlayPauseButton> & OnValueChangeProps<boolean>) => {
  const { isMuted, requestChangeMuteState } = useLiveStreamPlayerStateContext();
  return (
    <PlainMuteControlButton
      value={isMuted}
      onClick={(event) => {
        requestChangeMuteState(!isMuted);
        onValueChange?.(!isMuted);
        onClick?.(event);
      }}
      {...rest}
    />
  );
};

const SoundControlButtonGroup = ({ onMutedChange, ...rest }: ComponentProps<typeof PlainSoundControlButtonGroup>) => {
  const { volume, requestChangeVolume, isMuted, requestChangeMuteState } = useLiveStreamPlayerStateContext();
  return (
    <PlainSoundControlButtonGroup
      orientation="vertical"
      volume={volume}
      onVolumeChange={requestChangeVolume}
      isMuted={isMuted}
      onMutedChange={(newValue) => {
        requestChangeMuteState(newValue);
        onMutedChange?.(newValue);
      }}
      {...rest}
    />
  );
};

const LiveStreamPlayerControlsFull = {
  Container,
  ButtonsGroup,
  PlayPauseButton,
  MuteControlButton,
  SoundControlButtonGroup,
};

export default LiveStreamPlayerControlsFull;

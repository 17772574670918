import clsx from 'clsx';
import { ComponentProps, forwardRef } from 'preact/compat';

import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';

/**
 * It is used to maintain the video size, video player and other overlay components should place in this component.
 */
const HomeVideoPlayer = forwardRef<HTMLDivElement, Omit<ComponentProps<'div'>, 'ref' | 'className'>>(
  ({ children, ...rest }, ref) => {
    const isMobile = useIsMobileLayout();
    return (
      <div
        ref={ref}
        className={clsx(
          'group/home-player preflight relative shrink-0 overflow-hidden bg-slate-900',
          'group-data-[mini-mode=true]/home-player-container:aspect-video',
          'group-data-[mini-mode=false]/home-player-container:after:block group-data-[mini-mode=false]/home-player-container:after:pt-[min(56.25%,100vh-var(--headerHeight))]',
          isMobile
            ? 'group-data-[mini-mode=true]/home-player-container:h-[93px] group-data-[mini-mode=true]/home-player-container:w-[165px]'
            : 'group-data-[mini-mode=true]/home-player-container:rounded-lg',
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <div className="group-data-[mini-mode=false]/home-player-container:absolute group-data-[mini-mode=false]/home-player-container:inset-0 group-data-[mini-mode=true]/home-player-container:size-full">
          {children}
        </div>
      </div>
    );
  },
);

export default HomeVideoPlayer;

import clsx from 'clsx';
import { RefObject } from 'preact';
import { useEffect } from 'preact/hooks';

import HabitatLiveStreamPlayer from '@/components/HabitatLiveStreamPlayer';
import CameraSelector from '@/components/HabitatLiveStreamPlayer/Controls/CameraSelector';
import { zlSwitchCamera } from '@/components/icons';
import { useIsMobileLayout } from '@/components/LayoutDetector/hooks';
import { useLiveStreamPlayerStateContext } from '@/components/LiveStreamPlayer/contexts';
import LiveStreamPlayerControlsFull from '@/components/LiveStreamPlayer/LiveStreamPlayerControls/Full';
import LiveStreamPlayerControlsMinimized from '@/components/LiveStreamPlayer/LiveStreamPlayerControls/Minimized';
import LiveStreamPlayerLoadingContent from '@/components/LiveStreamPlayer/LiveStreamPlayerLoadingContent';
import { FullscreenControlButton, IconButton } from '@/components/VideoControls/buttons';

interface HomeLiveStreamPlayerOverlayProps {
  miniMode?: boolean;
  preferLiveStreamMutedRef: RefObject<boolean>;
  containerRef: RefObject<HTMLDivElement>;
}

const HomeLiveStreamPlayerOverlay = ({
  containerRef,
  miniMode,
  preferLiveStreamMutedRef,
}: HomeLiveStreamPlayerOverlayProps) => {
  const isMobileLayout = useIsMobileLayout();
  const { isPlaying, isVideoLoaded, requestChangePlayingState, requestChangeMuteState, requestFullscreenFromIFrame } =
    useLiveStreamPlayerStateContext();

  const onMutedChange = (newValue: boolean) => {
    // eslint-disable-next-line no-param-reassign
    preferLiveStreamMutedRef.current = newValue;
  };

  useEffect(() => {
    if (!isVideoLoaded) return;
    if (miniMode) {
      requestChangeMuteState(true);
    } else {
      requestChangeMuteState(!!preferLiveStreamMutedRef.current);
    }
  }, [miniMode, requestChangeMuteState, preferLiveStreamMutedRef, isVideoLoaded]);

  if (!isVideoLoaded) {
    return miniMode ? <p className="preflight preflight-p">Loading...</p> : <LiveStreamPlayerLoadingContent />;
  }

  if (!isPlaying) {
    return (
      <HabitatLiveStreamPlayer.FallbackContent>
        {miniMode ? (
          <p className="preflight preflight-p">Paused</p>
        ) : (
          <HabitatLiveStreamPlayer.PauseContent onClick={() => requestChangePlayingState(true)} />
        )}
      </HabitatLiveStreamPlayer.FallbackContent>
    );
  }

  return (
    <>
      {/* Live tag */}
      <div
        className={clsx(
          'absolute flex items-center gap-1 rounded bg-olive-4',
          miniMode ? 'right-2 top-2 px-1.5 py-1' : 'right-5 top-3 px-1.5 py-1 desktop:px-2',
        )}
      >
        <span className="size-1 rounded-full bg-system-red desktop:size-1.5" />
        <span className={clsx('font-bold leading-none', miniMode ? 'text-[8px]' : 'text-[10px] desktop:text-xs')}>LIVE</span>
      </div>

      {/* Controls */}
      {isMobileLayout ? (
        <LiveStreamPlayerControlsMinimized.Container className="group-data-[mini-mode=true]/home-player-container:hidden">
          <LiveStreamPlayerControlsMinimized.ExpandableControls
            renderControls={() => (
              <>
                <LiveStreamPlayerControlsMinimized.PlayPauseButton />
                <LiveStreamPlayerControlsMinimized.MuteControlButton onValueChange={onMutedChange} />
                <FullscreenControlButton
                  fullscreenElementRef={containerRef}
                  onFailToFullscreen={requestFullscreenFromIFrame}
                  renderButton={({ label, icon, onClick }) => (
                    <LiveStreamPlayerControlsMinimized.IconButton label={label} icon={icon} onClick={onClick} />
                  )}
                />
                <CameraSelector
                  renderButton={({ onClick }) => (
                    <LiveStreamPlayerControlsMinimized.IconButton
                      label="Switch camera"
                      icon={zlSwitchCamera}
                      onClick={onClick}
                    />
                  )}
                />
              </>
            )}
          />
        </LiveStreamPlayerControlsMinimized.Container>
      ) : (
        <LiveStreamPlayerControlsFull.Container
          className={clsx(
            'translate-y-full px-2 transition-transform group-hover/home-video-section:translate-y-0 mobile:text-[18px]',
            miniMode && '!hidden',
          )}
        >
          <LiveStreamPlayerControlsFull.ButtonsGroup>
            <LiveStreamPlayerControlsFull.PlayPauseButton />
            <LiveStreamPlayerControlsFull.SoundControlButtonGroup onMutedChange={onMutedChange} />
            <FullscreenControlButton fullscreenElementRef={containerRef} onFailToFullscreen={requestFullscreenFromIFrame} />
            <CameraSelector
              renderButton={({ onClick }) => <IconButton label="Switch camera" icon={zlSwitchCamera} onClick={onClick} />}
            />
          </LiveStreamPlayerControlsFull.ButtonsGroup>
        </LiveStreamPlayerControlsFull.Container>
      )}
    </>
  );
};

export default HomeLiveStreamPlayerOverlay;

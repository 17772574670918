import { memo } from 'preact/compat';
import { useContext, useMemo } from 'preact/hooks';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { ChatContext } from 'Shared/context';

import { useSelector } from '@/redux/helper';

/**
 * @param {object} props
 * @param {import('preact').ComponentChildren} props.children
 * @param {boolean} [props.isGuest]
 * @returns {import('preact').JSX.Element}
 */
const PubNubWrapper = ({ children, isGuest }) => {
  const { pubnub: parentPubnub } = useContext(ChatContext);
  const userId = useSelector((state) => state.user.userId);

  const pubnub = useMemo(
    () =>
      parentPubnub ??
      new PubNub({
        publishKey: process.env.PREACT_APP_PUBNUB_PUBLISH_KEY,
        subscribeKey: process.env.PREACT_APP_PUBNUB_SUBSCRIPTION_KEY,
        uuid: isGuest ? 'guest' : userId,
        autoNetworkDetection: true,
        logVerbosity: false,
      }),
    [isGuest, userId, parentPubnub],
  );

  return (
    <PubNubProvider client={pubnub}>
      <ChatContext.Provider value={{ pubnub }}>{children}</ChatContext.Provider>
    </PubNubProvider>
  );
};

export default memo(PubNubWrapper);

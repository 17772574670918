import { SignUpMessage } from '@/queries/community-feed/types';

import Card, { CardProps } from './Card';

const SignUpCard = ({ messageWithMeta }: CardProps<SignUpMessage>) => {
  return (
    <Card messageWithMeta={messageWithMeta}>
      <p className="preflight preflight-p">
        just joined zoolife, <b>welcome!</b>
      </p>
    </Card>
  );
};

export default SignUpCard;
